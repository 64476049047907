import Colors from "../utils/Colors";

export const DashedProgress = ({ width, percentage, height = 10 }) => {
  const spacing = 5;

  const dashes = new Array(Math.floor(width / spacing)).fill(null);
  const convertedPercentage = isNaN(percentage) ? 0 : percentage;

  return (
    <svg height={height} width={width}>
      <g>
        {dashes.map((_, index) => (
          <rect
            key={index}
            x="0"
            y="0"
            width="2"
            height={height}
            fill={
              ((index + 1) / dashes.length) * 100 > convertedPercentage
                ? Colors.gray
                : percentage < 33
                ? Colors.progressRed
                : percentage > 70
                ? Colors.primaryGreen
                : "#FED049"
            }
            transform={`translate(${spacing * index})`}
          />
        ))}
      </g>
    </svg>
  );
};
