import moment from "moment";
import React, { useCallback, useMemo, useState } from "react";
import { BrowserView, isMobile, MobileView } from "react-device-detect";
import "react-dropdown/style.css";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { PaddedCard } from "../../components/cards/PaddedCard";
import { CustomText } from "../../components/CustomText";
import Colors from "../../utils/Colors";
import "./ArchiveListPage.css";
import { ReactComponent as FilterIcon } from "../../assets/icons/filter-icon.svg";
import { ReactComponent as CaretLeft } from "../../assets/icons/CaretRight.svg";
import { ReactComponent as X } from "../../assets/icons/x.svg";
import { useFinishedContent } from "../../hooks/useFinishedContent";
import {
  FinishedContentDto,
  FinishedContentDtoTypeEnum,
  UpdateContentDtoTypeEnum,
} from "../../new-api/api";
import { ArchiveItemRow } from "../../components/ArchiveItemRow";
import { Spacer } from "../../components/styled-components";
import { FilterSidePane } from "../../components/FilterSidePane";
import { useRecoilState } from "recoil";
import { archivePageAtom, filtersAtom } from "../../state/state";
import { ITEMS_PER_PAGE } from "../../utils/shared";
import { useFinishedContentFilters } from "../../hooks/useFinishedContentFilters";
import { Loader } from "../../components/Loader";
import { useNewContentWithAttempts } from "../../hooks/useNewContentWithAttempts";

const HomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${Colors.background};
  flex: 1;
`;

const HomeContent = styled.div`
  max-width: 936px;
  width: 100%;
`;

const ItemsContainer = styled.div<{ hasItems?: boolean }>`
  overflow: scroll;
  height: 100%;
  width: 100%;
  ${({ hasItems }) =>
    !hasItems &&
    `display: flex; flex-direction: column; align-items: center; justify-content: center;`}
`;

const HeaderRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
  margin-bottom: 15px;
`;

const PaginationRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60px;
  border-top: 1px solid ${Colors.spacerGray};
`;

const PaginationItem = styled.div<{ isSelected: boolean }>`
  width: 50px;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-bottom: 2px solid
    ${({ isSelected }) => (isSelected ? Colors.primaryGreen : "transparent")};
`;

const FilterCard = styled.div`
  background-color: #f6f6f6;
  padding: 6px 12px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 10px;
`;

const FilterRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 10px;
  row-gap: 10px;
  margin: 24px;
  justify-content: flex-start;
`;

export const ArchiveListPage = () => {
  const { t } = useTranslation();
  const { data: newContentWithAttempts } = useNewContentWithAttempts();
  const { data: finishedContent, isLoading } = useFinishedContent();
  const [filters, setFilters] = useRecoilState(filtersAtom);
  const { data: allFilters } = useFinishedContentFilters();
  const allSelectedFilters = useMemo(
    () =>
      [
        ...(filters.periods || "")
          .split(",")
          .map((period) =>
            (allFilters?.periods || []).find(
              (p) => String(p.value) === String(period)
            )
          )
          .map((f) => ({ ...f, type: "periods" })),
        ...(filters.categories || "")
          .split(",")
          .map((category) =>
            (allFilters?.categories || []).find(
              (p) => String(p.value) === String(category)
            )
          )
          .map((f) => ({ ...f, type: "categories" })),
        ...(filters.contentTypes || "")
          .split(",")
          .map((contentType) =>
            (allFilters?.contentTypes || []).find(
              (p) => p.value === contentType
            )
          )
          .map((ct) => ({ ...ct, label: t(ct?.label), type: "contentTypes" })),
        ...(filters.creators || "")
          .split(",")
          .map((creator) =>
            (allFilters?.creators || []).find(
              (p) => String(p.value) === String(creator)
            )
          )
          .map((f) => ({ ...f, type: "creators" })),
      ].filter((f) => !!f && !!f?.value),
    [
      allFilters?.categories,
      allFilters?.contentTypes,
      allFilters?.creators,
      allFilters?.periods,
      filters.categories,
      filters.contentTypes,
      filters.creators,
      filters.periods,
      t,
    ]
  );
  const hasFilters = allSelectedFilters.length > 0;
  const [openPanel, setOpenPanel] = useState(false);
  const [currentPage, setCurrentPage] = useRecoilState(archivePageAtom);

  const sortFn = useCallback((a: FinishedContentDto, b: FinishedContentDto) => {
    if (
      !a?.contentInteractions?.[0]?.finishedAt ||
      !b?.contentInteractions?.[0]?.finishedAt
    ) {
      return -1;
    }
    if (
      moment(a?.contentInteractions?.[0]?.finishedAt).isAfter(
        moment(b?.contentInteractions?.[0]?.finishedAt)
      )
    ) {
      return -1;
    } else if (
      moment(a?.contentInteractions?.[0]?.finishedAt).isBefore(
        moment(b?.contentInteractions?.[0]?.finishedAt)
      )
    ) {
      return 1;
    } else {
      return 0;
    }
  }, []);
  const shouldDisplayNewContentWithAttempts = useMemo(
    () =>
      (hasFilters &&
        filters.contentTypes.includes(UpdateContentDtoTypeEnum.EDUCATION) &&
        currentPage === 0) ||
      (!hasFilters && currentPage === 0),
    [currentPage, filters.contentTypes, hasFilters]
  );

  const hasData =
    (finishedContent?.content || []).length > 0 ||
    (shouldDisplayNewContentWithAttempts &&
      (newContentWithAttempts?.content || []).length > 0);

  const noOfPages = Math.ceil(finishedContent?.count / ITEMS_PER_PAGE);
  const hasPagination = noOfPages > 1;
  return (
    <>
      <FilterSidePane openPanel={openPanel} setOpenPanel={setOpenPanel} />
      <HomeContainer>
        <HomeContent>
          <HeaderRow
            style={{
              marginLeft: isMobile ? 20 : 0,
              marginRight: isMobile ? 20 : 0,
              flexDirection: isMobile ? "column" : "row",
            }}
          >
            <CustomText fontSize={18} fontWeight={500} color={Colors.darkGreen}>
              {t("archive")}
            </CustomText>
            <div
              style={{ cursor: "pointer" }}
              onClick={() => setOpenPanel(true)}
            >
              <FilterIcon />
            </div>
          </HeaderRow>
          <BrowserView>
            <PaddedCard
              style={{
                display: "flex",
                minHeight: "20vh",
                justifyContent: "space-between",
                padding: 0,
                paddingBottom: hasPagination ? 0 : 40,
                flexDirection: "column",
              }}
            >
              {hasFilters && (
                <FilterRow>
                  {(allSelectedFilters || []).map((f) => (
                    <FilterCard key={f.value}>
                      <CustomText>{f?.label || ""}</CustomText>
                      <div
                        style={{ display: "flex", alignItems: "center" }}
                        onClick={() => {
                          const tempFilters = (filters[f?.type] || "")
                            .split(",")
                            .filter(
                              (filterProp: string) =>
                                String(filterProp) !== String(f?.value)
                            )
                            .join(",");
                          setFilters({ ...filters, [f.type]: tempFilters });
                        }}
                      >
                        <X />
                      </div>
                    </FilterCard>
                  ))}
                </FilterRow>
              )}
              {isLoading ? (
                <Loader />
              ) : (
                <ItemsContainer hasItems={hasData}>
                  {shouldDisplayNewContentWithAttempts &&
                    (newContentWithAttempts?.content || []).map(
                      (item, i, array) => (
                        <div key={item.id}>
                          <ArchiveItemRow
                            navigateTo={`/archive/${item.id}`}
                            item={item}
                          />
                          {array.length - 1 !== i && <Spacer />}
                        </div>
                      )
                    )}
                  {(finishedContent?.content || [])
                    .sort(sortFn)
                    .map((item, i, array) => (
                      <div key={item.id}>
                        <ArchiveItemRow
                          navigateTo={
                            item.type === FinishedContentDtoTypeEnum.TASK
                              ? `/task/${item.id}`
                              : `/archive/${item.id}`
                          }
                          item={item}
                        />
                        {array.length - 1 !== i && <Spacer />}
                      </div>
                    ))}
                </ItemsContainer>
              )}
              {hasPagination && (
                <PaginationRow>
                  <div
                    style={{
                      cursor: currentPage === 0 ? "not-allowed" : "pointer",
                    }}
                    onClick={() => setCurrentPage(Math.max(currentPage - 1, 0))}
                  >
                    <CaretLeft
                      width={50}
                      stroke={
                        currentPage === 0 ? Colors.disabled : Colors.primary
                      }
                    />
                  </div>
                  {[
                    currentPage - 2,
                    currentPage - 1,
                    currentPage,
                    currentPage + 1,
                    currentPage + 2,
                  ]
                    .filter((page) => page >= 0 && page < noOfPages)
                    .map((c) => (
                      <PaginationItem
                        key={c}
                        isSelected={currentPage === c}
                        onClick={() => setCurrentPage(c)}
                      >
                        <CustomText>{c + 1}</CustomText>
                      </PaginationItem>
                    ))}
                  <div
                    style={{
                      cursor:
                        currentPage === noOfPages - 1
                          ? "not-allowed"
                          : "pointer",
                    }}
                    onClick={() =>
                      setCurrentPage(Math.min(currentPage + 1, noOfPages - 1))
                    }
                  >
                    <CaretLeft
                      style={{ rotate: "180deg", width: 50 }}
                      stroke={
                        currentPage === noOfPages - 1
                          ? Colors.disabled
                          : Colors.primary
                      }
                    />
                  </div>
                </PaginationRow>
              )}
            </PaddedCard>
          </BrowserView>

          <MobileView>
            {isLoading ? (
              <Loader />
            ) : (
              <ItemsContainer
                hasItems={hasData}
                style={{ marginLeft: 20, marginRight: 20, paddingBottom: 20 }}
              >
                {shouldDisplayNewContentWithAttempts &&
                  (newContentWithAttempts?.content || []).map(
                    (item, i, array) => (
                      <ArchiveItemRow
                        navigateTo={`/archive/${item.id}`}
                        item={item}
                      />
                    )
                  )}
                {(finishedContent?.content || []).sort(sortFn).map((item) => (
                  <ArchiveItemRow
                    navigateTo={
                      item.type === FinishedContentDtoTypeEnum.TASK
                        ? `/task/${item.id}`
                        : `/archive/${item.id}`
                    }
                    key={item.id}
                    item={item}
                  />
                ))}
              </ItemsContainer>
            )}
          </MobileView>
        </HomeContent>
      </HomeContainer>
    </>
  );
};
