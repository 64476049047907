import ProgressBar from "@ramonak/react-progress-bar";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { BrowserView, isMobile, MobileView } from "react-device-detect";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import styled from "styled-components";
import { ReactComponent as X } from "../../assets/icons/x.svg";
import { ReactComponent as TimeIcon } from "../../assets/icons/Time.svg";
import { Button } from "../../components/Button";
import { PaddedCard } from "../../components/cards/PaddedCard";
import { CustomText } from "../../components/CustomText";
import { FormAnswerRow } from "../../components/FormAnswerRow";
import {
  currentFormAtom,
  currentPageAtom,
  remainingSecondsAtom,
} from "../../state/state";
import Colors from "../../utils/Colors";
import { useContentDetails } from "../../hooks/useContentDetails";
import {
  AnswerDto,
  ContentWithDetailsDtoTypeEnum,
  QuestionDtoTypeEnum,
} from "../../new-api/api";
import { arraysHaveSameNumbers } from "../../utils/shared";
import { useTranslation } from "react-i18next";
import { useTimer } from "react-timer-hook";
import { add } from "date-fns";
import { useContentQuestionsAndAnswers } from "../../hooks/useContentQuestionsAndAnswers";

const HomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${Colors.background};
  flex: 1;
`;

const HomeContent = styled.div`
  max-width: 936px;
  width: 100%;
`;

const HeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const QuestionHeaderContainer = styled.div`
  background-color: ${Colors.background};
  padding: 25px;
`;

const CloseButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  border-radius: 20px;
  background-color: ${Colors.secondaryBackground};
  cursor: pointer;
`;

const ItemsContainer = styled.div`
  position: relative;
  ${isMobile && "margin-bottom: 20px;"}
`;

const TextBox = styled.div<{
  isSelected: boolean;
  first: boolean;
  last: boolean;
}>`
  background-color: ${({ isSelected }) =>
    isSelected ? Colors.primaryGreen : Colors.background};
  align-items: center;
  justify-content: center;
  flex: 1;
  cursor: pointer;
  padding: 20px;
  border-top: 1px solid ${Colors.black10};
  border-bottom: 1px solid ${Colors.black10};
  border-left: 1px solid ${Colors.black10};
  font-size: 22px;
  line-height: 29px;
  text-align: center;
  color: ${Colors.darkGreen};

  ${({ first }) =>
    first &&
    `
    border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  `}

  ${({ last }) =>
    last &&
    `
    border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  border-right: 1px solid ${Colors.black10};
  `}
`;

const CustomTextArea = styled.textarea`
  margin-top: 20px;
  width: calc(100% - 40px);
  height: ${isMobile ? 110 : 300}px;
  border: 1px solid ${Colors.black10};
  border-radius: 4px;
  font-family: Archivo;
  font-size: 16px;
  padding: 20px;

  &:focus {
    outline: none !important;
    border: 1px solid ${Colors.primaryGreen};
  }
`;

const ButtonContainer = styled.div`
  width: ${isMobile ? "100%" : "inherit"};
  display: flex;
  flex-direction: column;
  ${isMobile && "align-items: center;"}
`;

export const FormPage = () => {
  const navigate = useNavigate();
  const [currentForm, setCurrentForm] = useRecoilState(currentFormAtom);
  const [currentPage, setCurrentPage] = useRecoilState(currentPageAtom);
  const [remainingSeconds, setRemainingSeconds] =
    useRecoilState(remainingSecondsAtom);
  const { t } = useTranslation();
  const [questionIndex, setQuestionIndex] = useState(currentPage);
  const [showIfCorrect, setShowIfCorrect] = useState(false);
  const { id } = useParams();
  const { data: contentQuestions } = useContentQuestionsAndAnswers(
    Number(id),
    true
  );
  const { data } = useContentDetails({ contentId: Number(id) });
  const [isFinished, setIsFinished] = useState(false);
  const [
    selectedAnswersForCurrentQuestion,
    setSelectedAnswersForCurrentQuestion,
  ] = useState<AnswerDto[]>([]);
  const [currentTextAnswer, setCurrentTextAnswer] = useState("");
  const textInputRef = useRef<HTMLTextAreaElement>(null);

  const numberOfQuestions = useMemo(
    () => (contentQuestions?.questions || []).length,
    [contentQuestions?.questions]
  );
  const isEducation = data?.type === ContentWithDetailsDtoTypeEnum.EDUCATION;
  const isLastQuestion = useMemo(
    () => questionIndex + 1 === numberOfQuestions,
    [numberOfQuestions, questionIndex]
  );
  const { seconds, restart, pause } = useTimer({
    expiryTimestamp: add(new Date(), {
      seconds: data?.settings?.timeRestriction,
    }),
    onExpire: () => {
      if (currentQuestion?.id) {
        setCurrentForm((prev) => [
          ...prev,
          { questionId: currentQuestion.id, answers: [] },
        ]);
      }
      handleGoNext();
    },
    autoStart: !!data?.settings?.timeRestriction,
  });

  useEffect(() => {
    if (data?.settings?.timeRestriction) {
      restart(
        add(new Date(), {
          seconds: remainingSeconds || data?.settings?.timeRestriction,
        }),
        true
      );
    }
  }, [data?.settings?.timeRestriction, remainingSeconds, restart]);

  const setRemainingSecondsListener = useCallback(() => {
    setRemainingSeconds(seconds);
  }, [seconds, setRemainingSeconds]);

  useEffect(() => {
    if (data?.settings?.timeRestriction) {
      window.addEventListener("beforeunload", setRemainingSecondsListener);
    }
    return () => {
      if (data?.settings?.timeRestriction) {
        window.removeEventListener("beforeunload", setRemainingSecondsListener);
      }
    };
  }, [data?.settings?.timeRestriction, setRemainingSecondsListener]);

  const currentQuestion = useMemo(
    () =>
      (contentQuestions?.questions || []).find(
        (q) => q.order === questionIndex
      ),
    [contentQuestions?.questions, questionIndex]
  );

  const allQuestionNumbers = useMemo(
    () => contentQuestions?.questions?.length || 0,
    [contentQuestions?.questions?.length]
  );

  const finish = useCallback(() => {
    const numOfCorrect = (contentQuestions?.questions || []).reduce(
      (previousValue, currentValue) => {
        const answeredQuestion = (currentForm || []).find(
          (qa) => qa.questionId === currentValue.id
        );
        const correctAnswers = (currentValue?.answers || [])
          .filter((ans) => ans.isCorrect)
          .map((ans) => ans.id);
        return arraysHaveSameNumbers(
          correctAnswers,
          (answeredQuestion?.answers || []).map((aq) => aq.id)
        )
          ? previousValue + 1
          : previousValue;
      },
      0
    );

    const finalMark = numOfCorrect / currentForm?.length;
    const answers = (currentForm || []).map((q) => ({
      questionId: q.questionId,
      answers:
        (q?.answers || []).length > 0 ? q.answers?.map((ans) => ans.id) : [],
      text: q.text,
    }));

    //@ts-ignore
    navigate("end", {
      state: {
        contentId: Number(id),
        answers: answers || [],
        numOfCorrect: isEducation ? numOfCorrect : null,
        finalMark: isEducation ? finalMark : null,
        passMark: isEducation ? data?.settings?.passingScore : null,
      },
    });
  }, [
    contentQuestions?.questions,
    data?.settings?.passingScore,
    isEducation,
    navigate,
    id,
    currentForm,
  ]);

  useEffect(() => {
    if (isFinished) {
      finish();
      setIsFinished(false);
    }
  }, [finish, isFinished]);

  const handleGoNext = useCallback(() => {
    setTimeout(
      () => {
        if (!isLastQuestion) {
          setSelectedAnswersForCurrentQuestion([]);
          setCurrentTextAnswer("");
          if (
            currentQuestion?.type === QuestionDtoTypeEnum.TEXT &&
            textInputRef?.current
          ) {
            textInputRef.current.value = "";
          }
          if (data?.settings?.timeRestriction) {
            pause();
            restart(
              add(new Date(), { seconds: data?.settings?.timeRestriction }),
              true
            );
          }
          setShowIfCorrect(false);
          setQuestionIndex(questionIndex + 1);
        } else {
          setIsFinished(true);
        }
        setRemainingSeconds(null);
      },
      isEducation ? 2000 : 500
    );
  }, [
    setRemainingSeconds,
    isEducation,
    isLastQuestion,
    currentQuestion?.type,
    data?.settings?.timeRestriction,
    questionIndex,
    pause,
    restart,
  ]);

  const isButtonDisabled = useMemo(() => {
    if (currentQuestion?.type === QuestionDtoTypeEnum.TEXT) {
      return currentTextAnswer.length === 0;
    }
    return selectedAnswersForCurrentQuestion.length === 0;
  }, [
    currentQuestion?.type,
    currentTextAnswer.length,
    selectedAnswersForCurrentQuestion.length,
  ]);

  const renderItem = useCallback(
    (item: AnswerDto, index: number, arr: AnswerDto[]) => {
      const isSelected = !!(selectedAnswersForCurrentQuestion || [])?.find(
        (ans) => ans?.id === item.id
      );

      if (currentQuestion?.type === QuestionDtoTypeEnum.LINEAR) {
        return (
          <TextBox
            key={item?.id}
            isSelected={isSelected}
            first={index === 0}
            last={index === 4}
            onClick={() => {
              setSelectedAnswersForCurrentQuestion([item]);
              setCurrentForm((prev) => [
                ...prev,
                { questionId: currentQuestion.id, answers: [item] },
              ]);
              handleGoNext();
            }}
          >
            <CustomText fontSize={22} fontWeight={500}>
              {item?.title}
            </CustomText>
          </TextBox>
        );
      } else {
        return (
          <FormAnswerRow
            answer={item}
            key={item?.id}
            showIfCorrect={showIfCorrect}
            isSelected={isSelected}
            onPress={() => {
              if (currentQuestion?.type === QuestionDtoTypeEnum.SINGLE) {
                if (isEducation) {
                  setShowIfCorrect(true);
                }
                setSelectedAnswersForCurrentQuestion([item]);
                setCurrentForm((prev) => [
                  ...prev,
                  { questionId: currentQuestion?.id, answers: [item] },
                ]);
                handleGoNext();
              } else {
                if (isSelected) {
                  setSelectedAnswersForCurrentQuestion((prev) =>
                    prev.filter((ans) => ans.id !== item.id)
                  );
                } else {
                  setSelectedAnswersForCurrentQuestion((prev) => [
                    ...prev,
                    item,
                  ]);
                }
              }
            }}
          />
        );
      }
    },
    [
      currentQuestion?.id,
      currentQuestion?.type,
      handleGoNext,
      isEducation,
      selectedAnswersForCurrentQuestion,
      setCurrentForm,
      showIfCorrect,
    ]
  );

  const innerContent = useMemo(
    () => (
      <>
        <QuestionHeaderContainer>
          <HeaderRow>
            <CustomText>
              {questionIndex + 1} / {allQuestionNumbers}
            </CustomText>
            <CloseButtonContainer
              onClick={() => {
                setCurrentForm([]);
                setCurrentPage(0);
                navigate(-1);
              }}
            >
              <X />
            </CloseButtonContainer>
          </HeaderRow>
          <CustomText
            fontSize={24}
            lineHeight={1.3}
            color={Colors.darkGray}
            fontWeight={500}
            style={{ marginTop: 20 }}
          >
            {currentQuestion?.title}
          </CustomText>
          {!!data?.settings?.timeRestriction && (
            <div
              style={{ display: "flex", alignItems: "center", columnGap: 10 }}
            >
              <TimeIcon />
              <CustomText fontSize={24}>{seconds}</CustomText>
              <CustomText fontSize={14}>{t("seconds")}</CustomText>
            </div>
          )}
        </QuestionHeaderContainer>
        {!!data?.settings?.timeRestriction && (
          <ProgressBar
            completed={seconds}
            borderRadius={"0"}
            bgColor={Colors.primaryGreen}
            baseBgColor={Colors.white}
            isLabelVisible={false}
            height={"3px"}
            maxCompleted={data?.settings?.timeRestriction}
          />
        )}
        <ItemsContainer
          style={
            currentQuestion?.type === QuestionDtoTypeEnum.LINEAR
              ? {
                  flexDirection: "row",
                  display: "flex",
                  marginTop: 30,
                }
              : {}
          }
        >
          {(currentQuestion?.answers || []).map(renderItem)}
        </ItemsContainer>
        {currentQuestion?.type === QuestionDtoTypeEnum.TEXT && (
          <div
            style={{
              marginBottom: isMobile ? 20 : 0,
              paddingLeft: isMobile ? 20 : 0,
              paddingRight: isMobile ? 20 : 0,
            }}
          >
            <CustomTextArea
              id="w3review"
              style={{}}
              ref={textInputRef}
              onChange={(event) => setCurrentTextAnswer(event.target.value)}
              autoFocus={!isMobile}
            />
          </div>
        )}
      </>
    ),
    [
      allQuestionNumbers,
      currentQuestion?.answers,
      currentQuestion?.title,
      currentQuestion?.type,
      data?.settings?.timeRestriction,
      navigate,
      questionIndex,
      renderItem,
      seconds,
      setCurrentForm,
      setCurrentPage,
      t,
    ]
  );

  return (
    <HomeContainer>
      <HomeContent>
        <BrowserView renderWithFragment>
          <PaddedCard style={{ marginTop: 30, marginBottom: 20 }}>
            {innerContent}
          </PaddedCard>
        </BrowserView>
        <MobileView>{innerContent}</MobileView>
        {[QuestionDtoTypeEnum.TEXT, QuestionDtoTypeEnum.MULTIPLE].includes(
          currentQuestion?.type
        ) && (
          <ButtonContainer>
            <Button
              buttonType="primary"
              text={
                isLastQuestion
                  ? (t("done") as string).toUpperCase()
                  : (t("next") as string).toUpperCase()
              }
              style={{ width: isMobile ? "90%" : "40%" }}
              onClick={() => {
                if (data.type === ContentWithDetailsDtoTypeEnum.EDUCATION) {
                  setShowIfCorrect(true);
                }
                if (
                  currentQuestion.type === QuestionDtoTypeEnum.TEXT &&
                  currentTextAnswer.length > 0
                ) {
                  setCurrentForm((prev) => [
                    ...prev,
                    { questionId: currentQuestion.id, text: currentTextAnswer },
                  ]);
                } else if (
                  currentQuestion.type === QuestionDtoTypeEnum.MULTIPLE
                ) {
                  setCurrentForm((prev) => [
                    ...prev,
                    {
                      questionId: currentQuestion.id,
                      answers: selectedAnswersForCurrentQuestion,
                    },
                  ]);
                }
                handleGoNext();
              }}
              disabled={isButtonDisabled || (showIfCorrect && isEducation)}
            />
          </ButtonContainer>
        )}
      </HomeContent>
    </HomeContainer>
  );
};
