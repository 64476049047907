import { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { ReactComponent as FileIcon } from "../../../assets/icons/file.svg";
import { ReactComponent as Check } from "../../../assets/icons/check.svg";
import { Button } from "../../../components/Button";
import { CustomText } from "../../../components/CustomText";
import Colors from "../../../utils/Colors";
import { BASE_URL } from "../../../utils/shared";

const DocumentContainer = styled.div`
  padding: 7.5px;
  border: 1px solid ${Colors.backgroundGray};
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 14px;
`;

export const DocumentItem = ({
  document,
  setNumOfDocSeen,
  isArchive,
}: {
  document: { url: string; name: string };
  setNumOfDocSeen: any;
  isArchive: boolean;
}) => {
  const [hasSeenDoc, setHasSeenDoc] = useState(false);
  const { t } = useTranslation();

  return (
    <DocumentContainer>
      <div style={{ display: "flex", alignItems: "center" }}>
        <FileIcon />
        <div style={{ marginLeft: 8, justifyContent: "space-between" }}>
          <CustomText style={{ maxWidth: 500 }}>{document.name}</CustomText>
        </div>
      </div>
      {hasSeenDoc && !isArchive ? (
        <Check
          fill={Colors.primaryGreen}
          width={20}
          style={{ marginRight: 10 }}
        />
      ) : (
        <a
          href={`${BASE_URL}/files/${document?.url}`}
          target="_blank"
          rel="noreferrer"
        >
          <Button
            text={t("open")}
            buttonType="secondary"
            icon={null}
            style={{
              minWidth: 100,
              border: 0,
              boxShadow: "unset",
              color: Colors.primaryGreen,
              fontWeight: 500,
              textDecoration: "none",
            }}
            onClick={() => {
              if (document?.url) {
                setHasSeenDoc(true);
                setNumOfDocSeen((num) => num + 1);
              }
            }}
          />
        </a>
      )}
    </DocumentContainer>
  );
};
