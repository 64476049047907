import React, { useMemo } from "react";
import styled from "styled-components";
import { PaddedCard } from "../../components/cards/PaddedCard";
import { CustomText } from "../../components/CustomText";
import { ItemRow } from "../../components/ItemRow";
import { useNewContent } from "../../hooks/useNewContent";
import { useUser } from "../../hooks/useUser";
import Colors from "../../utils/Colors";
import { pluralize } from "../../utils/plurals";
import { BrowserView, isMobile, MobileView } from "react-device-detect";
import { Ring } from "@uiball/loaders";
import { ReactComponent as InboxIn } from "../../assets/icons/tab-bar-icons/inbox-in.svg";
import { EmptyState } from "../../components/EmptyState";
import { useTranslation } from "react-i18next";
import { Spacer } from "../../components/styled-components";
import { TaskItemRow } from "../../components/TaskItemRow";
import { Loader } from "../../components/Loader";
import { ContentDto, ContentDtoTypeEnum } from "../../new-api/api";

const HomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${Colors.background};
  flex: 1;
`;

const HomeContent = styled.div`
  max-width: 936px;
  width: 100%;
`;

const ItemsContainer = styled.div<{ hasItems?: boolean }>`
  overflow: scroll;
  height: 100%;
  width: 100%;
  ${({ hasItems }) =>
    !hasItems &&
    `display: flex; flex-direction: column; align-items: center; justify-content: center;`}
`;

export const HomePage = () => {
  const { data: userData } = useUser();
  const { data: newContent, isLoading } = useNewContent();
  const { t } = useTranslation();
  const totalContentCount = useMemo(
    () => newContent?.count || 0,
    [newContent?.count]
  );
  const taskContent = useMemo(
    () =>
      (newContent?.content || []).filter(
        (c) => c.type === ContentDtoTypeEnum.TASK
      ),
    [newContent?.content]
  ) as ContentDto[];

  const restOfContent = useMemo(
    () =>
      (newContent?.content || []).filter(
        (c) => c.type !== ContentDtoTypeEnum.TASK
      ),
    [newContent?.content]
  ) as ContentDto[];

  const hasContent = (totalContentCount || 0) > 0;

  return (
    <HomeContainer>
      <HomeContent>
        <CustomText
          style={{
            marginTop: 30,
            marginBottom: 15,
            whiteSpace: "pre-line",
            marginLeft: isMobile ? 20 : 0,
            marginRight: isMobile ? 20 : 0,
          }}
          fontSize={18}
          lineHeight={1.4}
        >
          {pluralize({
            lng: "hr",
            count: isNaN(totalContentCount) ? 0 : totalContentCount,
            transKey: "homeHeaderMessage",
            variable: { name: userData?.name || "" },
          })}
        </CustomText>
        <BrowserView>
          {(taskContent || []).length > 0 && (
            <CustomText
              fontSize={18}
              fontWeight={500}
              style={{ marginBottom: 26 }}
            >
              {t("tasks").toUpperCase()}
            </CustomText>
          )}
          {(taskContent || []).length > 0 && (
            <PaddedCard
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: isLoading && "center",
                marginBottom: 26,
                padding: 0,
              }}
            >
              <ItemsContainer hasItems={hasContent}>
                {(taskContent || []).map((item, i, array) => (
                  <TaskItemRow
                    navigateTo={`/task/${item.id}`}
                    hidePicture
                    key={item.id}
                    item={item}
                  />
                ))}
              </ItemsContainer>
            </PaddedCard>
          )}
          {(taskContent || []).length > 0 && (
            <CustomText
              fontSize={18}
              fontWeight={500}
              style={{ marginBottom: 26 }}
            >
              {t("content").toUpperCase()}
            </CustomText>
          )}
          <PaddedCard
            style={{
              minHeight: "64vh",
              display: "flex",
              alignItems: "center",
              justifyContent: isLoading && "center",
              marginBottom: 40,
              padding: 0,
            }}
          >
            {isLoading ? (
              <Loader />
            ) : (
              <ItemsContainer hasItems={hasContent}>
                {restOfContent?.length > 0 ? (
                  (restOfContent || []).map((item, i, array) => (
                    <>
                      <ItemRow
                        navigateTo={`/content/${item.id}/intro`}
                        key={item?.id}
                        item={item}
                      />
                      {array.length - 1 !== i && (
                        <Spacer key={item.id + "spacer"} />
                      )}
                    </>
                  ))
                ) : (
                  <EmptyState
                    headerText={t("noActivity")}
                    icon={InboxIn}
                    infoText={t("noActivityInfo")}
                  />
                )}
              </ItemsContainer>
            )}
          </PaddedCard>
        </BrowserView>
        <MobileView>
          {isLoading ? (
            <Ring color={Colors.primaryGreen} />
          ) : (
            <ItemsContainer
              hasItems={restOfContent.length > 0}
              style={{ marginLeft: 20, marginRight: 20, paddingBottom: 20 }}
            >
              {restOfContent.length > 0 ? (
                restOfContent.map((item) => (
                  <ItemRow
                    navigateTo={
                      item?.type === ContentDtoTypeEnum.INFORMATION
                        ? `/content/${item.id}`
                        : `/content/${item.id}/intro`
                    }
                    key={item.id}
                    item={item}
                  />
                ))
              ) : (
                <EmptyState
                  headerText={t("noActivity")}
                  icon={InboxIn}
                  infoText={t("noActivityInfo")}
                />
              )}
            </ItemsContainer>
          )}
        </MobileView>
      </HomeContent>
    </HomeContainer>
  );
};
