import { Ring } from "@uiball/loaders";
import React, { useCallback, useMemo } from "react";
import { BrowserView, isMobile, MobileView } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { AnsweredQuestion } from "../../components/AnsweredQuestion";
import { CustomText } from "../../components/CustomText";
import Colors from "../../utils/Colors";
import { useContentDetails } from "../../hooks/useContentDetails";
import {
  ContentWithDetailsDtoTypeEnum,
  UserAnswerDto,
} from "../../new-api/api";
import { BackButton } from "../../components/BackButton";
import { Button } from "../../components/Button";
import { ButtonContainer } from "../../components/styled-components";
import { Loader } from "../../components/Loader";
import { useUserFormAttemptsByContent } from "../../hooks/useUserFormAttempts";
import { useContentMaterialAndMedia } from "../../hooks/useContentMaterialAndMedia";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 40px;
  background-color: ${isMobile ? Colors.white : Colors.background};
  flex: 1;
`;

const Content = styled.div<{ isLoading: boolean }>`
  display: flex;
  flex-direction: column;
  max-width: 936px;
  justify-content: ${({ isLoading }) => (isLoading ? "center" : "flex-start")};
  width: 100%;
  border-radius: 6px;
  background-color: white;
  margin-top: 50px;
  padding-top: 20px;
  padding-bottom: 40px;
`;

const InnerContent = styled.div`
  margin-left: 36px;
  margin-right: 36px;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
`;

export const ArchiveQuestionsPage = () => {
  const { id } = useParams();
  const { data: content, isLoading } = useContentDetails({
    contentId: Number(id),
  });
  const { data: userAttempts } = useUserFormAttemptsByContent(Number(id), true);
  const { data: contentMaterial } = useContentMaterialAndMedia(Number(id));

  const { t } = useTranslation();
  const navigate = useNavigate();

  const attempt = useMemo(
    () =>
      (userAttempts?.attempts || []).sort((a, b) =>
        a.id > b.id ? -1 : 1
      )?.[0],
    [userAttempts?.attempts]
  );
  const renderItem = useCallback(
    ({ item }: { item: UserAnswerDto }) => (
      <AnsweredQuestion
        formType={content.type}
        userAnswer={item}
        key={item.id}
      />
    ),
    [content.type]
  );

  const innerContent = useMemo(
    () => (
      <InnerContent>
        {[
          ContentWithDetailsDtoTypeEnum.POLL,
          ContentWithDetailsDtoTypeEnum.EDUCATION,
          ContentWithDetailsDtoTypeEnum.INQUIRY,
        ].includes(content.type) && (
          <>
            <CustomText fontSize={24} fontWeight={500} color={Colors.darkGray}>
              {t("questionsAndAnswers")}
            </CustomText>
            {(attempt?.userAnswers || [])?.map((q, i) =>
              renderItem({ item: q })
            )}
          </>
        )}
        {(content?.comments || []).length > 0 && (
          <div
            style={{
              borderTopWidth: 1,
              borderTopColor: Colors.black10,
              paddingTop: 20,
            }}
          >
            <CustomText
              fontSize={20}
              lineHeight={1}
              fontWeight={500}
              color={Colors.darkGray}
              style={{ marginBottom: 10 }}
            >
              {t("myComments")}
            </CustomText>
            {(content?.comments || []).map((c) => (
              <CustomText key={c.id}>{c?.title || ""}</CustomText>
            ))}
          </div>
        )}
        <ButtonContainer>
          <BackButton backTo={`/archive/${content?.id}`} />
          {(contentMaterial?.material?.link ||
            contentMaterial?.material?.text ||
            (contentMaterial?.material?.medias || []).length > 0) && (
            <Button
              text={t("reviewContent")}
              onClick={() => navigate(`/archive/${content?.id}/content`)}
              buttonType="primary"
            />
          )}
        </ButtonContainer>
      </InnerContent>
    ),
    [
      attempt?.userAnswers,
      content?.comments,
      content?.id,
      contentMaterial?.material?.link,
      contentMaterial?.material?.medias,
      contentMaterial?.material?.text,
      content.type,
      navigate,
      renderItem,
      t,
    ]
  );

  return (
    <Container>
      <BrowserView renderWithFragment>
        <Content isLoading={isLoading}>
          {isLoading ? <Loader /> : innerContent}
        </Content>
      </BrowserView>
      <MobileView>
        {isLoading ? <Ring color={Colors.primaryGreen} /> : innerContent}
      </MobileView>
    </Container>
  );
};
