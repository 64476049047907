import { useQuery } from 'react-query';
import { useRecoilValue } from "recoil";
import { tokenAtom, userIdAtom } from "../state/state";
import { axiosInstance } from "../api/http";

export const useUser = () => {
  const token = useRecoilValue(tokenAtom)
  const userId = useRecoilValue(userIdAtom)

  const storeKey = ['user', userId, token];
  return useQuery(storeKey, axiosInstance.mobile.usersControllerGetUserData, {
    enabled: !!userId,
    retry: false,
    select: ({ data }) => data.payload
  });
};
