import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { ReactComponent as EtenimLogo } from "../../assets/icons/etenim-logo.svg";
import { Button } from "../../components/Button";
import { PaddedCard } from "../../components/cards/PaddedCard";
import { CustomText } from "../../components/CustomText";
import Colors from "../../utils/Colors";
const backgroundImage = require("../../assets/images/etenim-background.png");

const StyledEtenimLogo = styled(EtenimLogo)`
  position: absolute;
  top: 90px;
`;

const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  background: url(${backgroundImage}) repeat 0 0;
  background-color: ${Colors.background};
  min-height: 100%;
  background-size: 100%;
`;

export const ForgotPasswordSuccessPage = () => {
  const location = useLocation();

  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <LoginContainer>
      <StyledEtenimLogo />
      <PaddedCard>
        <CustomText fontWeight={400} fontSize={24}>
          {t("checkEmail")}
        </CustomText>
        <CustomText
          style={{ maxWidth: 310, marginBottom: 20 }}
          fontSize={13}
          lineHeight={1.5}
        >
          {t("checkEmailText", {
            //@ts-ignore
            email: location.state?.email,
          })}
        </CustomText>
        <Button
          text={t("login")}
          buttonType="primary"
          type="submit"
          onClick={() => navigate("/login")}
        />
      </PaddedCard>
    </LoginContainer>
  );
};
