import styled from "styled-components";
import Colors from "../utils/Colors";
import { isMobile } from "react-device-detect";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${Colors.background};
  flex: 1;
`;

export const Content = styled.div`
  max-width: 936px;
  width: 100%;
`;

export const Spacer = styled.div`
  height: 1px;
  background-color: ${Colors.spacerGray};
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
  column-gap: 16px;
  ${isMobile && "align-items: center; flex-direction: column;"}
`;
