import { useMutation, useQueryClient } from 'react-query';
import { CreateUserAnswerDTO } from '../new-api/api';
import { useRecoilValue } from "recoil";
import { userIdAtom } from "../state/state";
import { axiosInstance } from "../api/http";

export const useContentFunctions = ({ contentId }: { contentId: number }) => {
  const userId = useRecoilValue(userIdAtom)
  const queryClient = useQueryClient();
  const markContentViewed = useMutation(() => axiosInstance.mobile.contentControllerMarkViewedContent({ contentId }));

  const markContentFinished = useMutation(
    () => axiosInstance.mobile.contentControllerMarkFinishedContent({ contentId }),
    {
      onSuccess: async () => {
        await queryClient.refetchQueries({
          queryKey: ['user', userId, 'new-content']
        });

        await queryClient.refetchQueries({
          queryKey: ['user', userId, 'finished-content']
        });
        await queryClient.refetchQueries({ queryKey: ['user', userId, 'finished-content-filters'] });
      }
    }
  );

  const submitAnswers = useMutation((props: CreateUserAnswerDTO) =>
    axiosInstance.mobile.contentControllerSubmitAnswers(contentId, props)
  );

  const commentContent = useMutation(({ comment }: { comment: string }) =>
    axiosInstance.mobile.contentControllerCommentContent({ comment, contentId })
  );

  return {
    submitAnswers,
    commentContent,
    markContentViewed,
    markContentFinished
  };
};
