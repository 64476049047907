import { useMemo } from "react";
import { useQuery } from "react-query";
import { axiosInstance } from "../api/http";
import { useRecoilValue } from "recoil";
import { userIdAtom } from "../state/state";

export const useContentMaterialAndMedia = (contentId: number) => {
  const storeKey = useMemo(
    () => ["content", contentId, "material-media"],
    [contentId]
  );
  const userId = useRecoilValue(userIdAtom);

  return useQuery(
    storeKey,
    () =>
      axiosInstance.mobile.contentControllerGetContentMaterialWithMediaById(
        contentId
      ),
    {
      enabled: !!userId && !!contentId,
      select: ({ data }) => data.payload,
    }
  );
};
