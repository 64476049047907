import Colors from "../utils/Colors";
import { Ring } from "@uiball/loaders";
import React from "react";

export const Loader = () => {
  return (
    <div
      style={{
        width: "100%",
        minHeight: "25vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Ring color={Colors.primaryGreen} />
    </div>
  );
};
