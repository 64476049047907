import { useQuery } from 'react-query';
import { useRecoilValue } from "recoil";
import { userIdAtom } from "../state/state";
import { axiosInstance } from "../api/http";

export const useFinishedContentFilters = () => {
  const userId = useRecoilValue(userIdAtom)

  const storeKey = ['user', userId, 'finished-content-filters'];

  return useQuery(storeKey, axiosInstance.mobile.contentControllerGetFinishedContentFilters, {
    enabled: !!userId,
    select: ({ data }) => data.payload
  });
};
