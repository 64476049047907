import { Ring } from "@uiball/loaders";
import moment from "moment";
import React, { useMemo } from "react";
import { BrowserView, isMobile, MobileView } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { Button } from "../../components/Button";
import { CustomText } from "../../components/CustomText";
import {
  ButtonContainer,
  Container,
  Content as ExContent,
} from "../../components/styled-components";
import Colors from "../../utils/Colors";
import { BASE_URL } from "../../utils/shared";
import "./ContentIntroductionPage.css";
import { useContentDetails } from "../../hooks/useContentDetails";
import {
  ContentWithDetailsDtoTypeEnum,
  MediaResponseDtoTypeEnum,
} from "../../new-api/api";
import { BackButton } from "../../components/BackButton";
import { Loader } from "../../components/Loader";
import { useContentMaterialAndMedia } from "../../hooks/useContentMaterialAndMedia";
import { useContentQuestionsAndAnswers } from "../../hooks/useContentQuestionsAndAnswers";

const Content = styled(ExContent)<{ isLoading: boolean }>`
  display: flex;
  justify-content: ${({ isLoading }) => (isLoading ? "center" : "flex-start")};
  flex-direction: column;
  border-radius: 6px;
  background-color: white;
  margin-top: 50px;
  padding-top: 40px;
  padding-bottom: 40px;
`;

const InnerContent = styled.div`
  margin-left: 36px;
  margin-right: 36px;
`;

const UpperContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const RowContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 20px;
`;

const ImageContainer = styled.div`
  margin-bottom: 20px;
  margin-top: 10px;
`;

const ContentInfoContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-left: ${isMobile ? 20 : 0}px;
  padding-right: ${isMobile ? 20 : 0}px;
`;

const ContentInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 5px;
  padding-bottom: 5px;
  border-bottom: 1px solid ${Colors.backgroundGray};
`;

export interface IContentQuantity {
  questions?: number;
  images: number;
  videos: number;
  documents: number;
}

export const ContentIntroductionPage = ({
  isArchive,
}: {
  isArchive?: boolean;
}) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { data: content, isLoading } = useContentDetails({
    contentId: Number(id),
  });
  const { data: contentQuestions } = useContentQuestionsAndAnswers(
    Number(id),
    true
  );
  const { data: contentMaterial } = useContentMaterialAndMedia(Number(id));
  const { t } = useTranslation();

  const numOfImages = (contentMaterial?.material?.medias || []).filter(
    (c) => c.type === MediaResponseDtoTypeEnum.IMAGE
  ).length;

  const numOfVideos =
    (contentMaterial?.material?.medias || []).filter(
      (c) => c.type === MediaResponseDtoTypeEnum.VIDEO
    ).length +
      (contentMaterial?.material?.link || "").length >
    0
      ? 1
      : 0;

  const numOfDocuments = (contentMaterial?.material?.medias || []).filter(
    (c) => c.type === MediaResponseDtoTypeEnum.DOCUMENT
  ).length;

  const contentQuantity = React.useMemo(() => {
    return (
      content?.type === ContentWithDetailsDtoTypeEnum.INFORMATION
        ? {
            images: numOfImages,
            videos: numOfVideos,
            documents: numOfDocuments,
          }
        : {
            questions: contentQuestions?.questions?.length || 0,
            images: numOfImages,
            videos: numOfVideos,
            documents: numOfDocuments,
          }
    ) as IContentQuantity;
  }, [
    content?.type,
    contentQuestions?.questions?.length,
    numOfDocuments,
    numOfImages,
    numOfVideos,
  ]);

  const hasContent = useMemo(
    () => numOfDocuments > 0 || numOfImages > 0 || numOfVideos > 0,
    [numOfDocuments, numOfImages, numOfVideos]
  );

  const shouldGoToQuestions = useMemo(() => {
    return (
      content?.type !== ContentWithDetailsDtoTypeEnum.INFORMATION && !hasContent
    );
  }, [content?.type, hasContent]);

  const innerContent = useMemo(
    () => (
      <InnerContent>
        <RowContainer>
          <CustomText
            fontSize={13}
            lineHeight={1.4}
            color={Colors.gray}
            uppercase
            style={{ marginBottom: 10 }}
          >
            {t(content?.type) || ""}
          </CustomText>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              columnGap: 4,
            }}
          >
            <CustomText color={Colors.gray} fontSize={13}>
              {`OBJAVLJENO:`}
            </CustomText>
            <CustomText color={Colors.darkGray} fontWeight={500} fontSize={13}>
              {/*@ts-ignore*/}
              {moment(content?.publishAt).format("DD.MM.YYYY.")}
            </CustomText>
          </div>
        </RowContainer>
        {content?.image ? (
          <ImageContainer>
            <img
              src={`${BASE_URL}/files/${content?.image}`}
              crossOrigin={"use-credentials"}
              alt="Item"
              width={"100%"}
              height={200}
              style={{
                objectFit: "cover",
                objectPosition: "center",
              }}
            />
          </ImageContainer>
        ) : null}
        <RowContainer style={{ alignItems: "flex-start" }}>
          <UpperContent>
            <CustomText
              fontSize={20}
              lineHeight={1}
              fontWeight={500}
              color={Colors.darkGray}
              style={{ marginBottom: 10 }}
            >
              {content?.name || ""}
            </CustomText>

            <CustomText
              fontSize={14}
              lineHeight={1.4}
              color={Colors.darkGray}
              style={{ marginBottom: 12 }}
            >
              {content?.description || ""}
            </CustomText>
          </UpperContent>
          <ContentInfoContainer>
            {Object.keys(contentQuantity)
              .filter((objKey) => contentQuantity[objKey] > 0)
              .map((objKey) => {
                return (
                  <ContentInfo key={objKey}>
                    <CustomText uppercase fontSize={13} fontWeight={500}>
                      {t(objKey)}
                    </CustomText>
                    <CustomText fontSize={16} fontWeight={700}>
                      {contentQuantity[objKey]}
                    </CustomText>
                  </ContentInfo>
                );
              })}
            {content?.contentInteractions?.[0]?.finishedAt && (
              <ContentInfo>
                <CustomText uppercase fontSize={13} fontWeight={500}>
                  {t("seen")}
                </CustomText>
                <CustomText fontSize={16} fontWeight={700}>
                  {moment(content?.contentInteractions?.[0]?.finishedAt).format(
                    "DD.MM.YYYY"
                  )}
                </CustomText>
              </ContentInfo>
            )}
          </ContentInfoContainer>
        </RowContainer>
        {content?.type === ContentWithDetailsDtoTypeEnum.POLL && (
          <CustomText style={{ marginTop: 12 }}>{t("pollIsAnon")}</CustomText>
        )}
        {!isArchive && (
          <ButtonContainer>
            <BackButton backTo={"/"} />
            <Button
              text={shouldGoToQuestions ? t("answerQuestions") : t("go")}
              onClick={() =>
                shouldGoToQuestions
                  ? navigate(
                      `/content/${content?.id}/${
                        content?.type ===
                        ContentWithDetailsDtoTypeEnum.EDUCATION
                          ? "quiz"
                          : "poll"
                      }`
                    )
                  : navigate(`/content/${id}`)
              }
              buttonType="primary"
            />
          </ButtonContainer>
        )}
        {isArchive && (
          <ButtonContainer>
            <BackButton backTo={`/archive`} />
            {hasContent && (
              <Button
                text={t("reviewContent")}
                onClick={() => navigate(`content`)}
                buttonType="primary"
              />
            )}
            {![ContentWithDetailsDtoTypeEnum.INFORMATION].includes(
              content?.type
            ) && (
              <Button
                text={t("reviewAnswers")}
                onClick={() => navigate(`questions`)}
                buttonType="primary"
              />
            )}
          </ButtonContainer>
        )}
      </InnerContent>
    ),
    [
      content?.contentInteractions,
      content?.description,
      content?.id,
      content?.image,
      content?.name,
      content?.publishAt,
      content?.type,
      contentQuantity,
      hasContent,
      id,
      isArchive,
      navigate,
      shouldGoToQuestions,
      t,
    ]
  );

  return (
    <Container
      style={{ backgroundColor: isMobile ? Colors.white : Colors.background }}
    >
      <BrowserView renderWithFragment>
        <Content isLoading={isLoading}>
          {isLoading || !content ? <Loader /> : innerContent}
        </Content>
      </BrowserView>
      <MobileView>
        {isLoading || !content ? (
          <Ring color={Colors.primaryGreen} />
        ) : (
          innerContent
        )}
      </MobileView>
    </Container>
  );
};
