import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { Header } from "../components/Header";
import { tokenAtom, userIdAtom } from "../state/state";

export const ProtectedRoute = ({ redirectPath = "/login", children }) => {
  const token = useRecoilValue(tokenAtom);
  const userId = useRecoilValue(userIdAtom)
  const location = useLocation();

  if (!token || !userId) {
    return <Navigate to={redirectPath} replace state={{ from: location }} />;
  }

  return children ? (
    <>
      <Header />
      {children}
    </>
  ) : (
    <>
      <div style={{ height: "80px" }}>
        <Header />
      </div>
      <Outlet />
    </>
  );
};
