import moment from "moment";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Colors from "../utils/Colors";
import { CustomText } from "./CustomText";
import { ReactComponent as ArrowRight } from "../assets/icons/ArrowRight.svg";

import { ContentDto, FinishedContentDto } from "../new-api/api";
import { useTranslation } from "react-i18next";
import { useContentFunctions } from "../hooks/useContentFunctions";

const ItemRowComponent = styled.div`
  display: flex;
  padding: 15px 21px;
`;

interface Props {
  item: ContentDto | FinishedContentDto;
  index?: number;
  shouldGoToAnswers?: boolean;
  hidePicture?: boolean;
  navigateTo?: string;
}

export const TaskItemRow: FC<Props> = ({ item }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    markContentViewed: { mutateAsync: markContentViewed },
  } = useContentFunctions({
    contentId: Number(item.id),
  });

  return (
    <ItemRowComponent>
      <div style={{ display: "flex", flex: 1, alignItems: "center" }}>
        <CustomText
          fontSize={20}
          lineHeight={1.2}
          fontWeight={500}
          color={Colors.darkGray}
        >
          {item.name}
        </CustomText>
      </div>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          columnGap: 4,
          flex: 1,
        }}
      >
        <CustomText color={Colors.gray} fontSize={14}>
          {t("taskBy")}:
        </CustomText>
        <CustomText color={Colors.darkGray} fontWeight={500} fontSize={14}>
          {/*@ts-ignore*/}
          {item?.creator?.name}
        </CustomText>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          columnGap: 4,
          flex: 1,
        }}
      >
        <CustomText color={Colors.gray} fontSize={14}>
          {t("deadline2")}:
        </CustomText>
        <CustomText color={Colors.darkGray} fontWeight={500} fontSize={14}>
          {/*@ts-ignore*/}
          {moment(item?.task?.deadline).format("DD.MM.YYYY.")}
        </CustomText>
      </div>
      <div
        style={{ cursor: "pointer", display: "flex", alignItems: "center" }}
        onClick={() => {
          markContentViewed();
          navigate(`/task/${item.id}`);
        }}
      >
        <ArrowRight width={24} height={24} stroke={Colors.primaryGreen} />
      </div>
    </ItemRowComponent>
  );
};
