const translations = (variable: { name: any }, count: number) => ({
  hr: {
    homeHeaderMessage_0: `Pozdrav ${variable?.name},\n Nemate ni jedan nepregledan sadržaj`,
    homeHeaderMessage_1: `Pozdrav ${variable?.name},\n Imate 1 nepregledan sadržaj`,
    homeHeaderMessage_2: `Pozdrav ${variable?.name},\n Imate 2 nepregledana sadržaja`,
    homeHeaderMessage_3: `Pozdrav ${variable?.name},\n Imate 3 nepregledana sadržaja`,
    homeHeaderMessage_4: `Pozdrav ${variable?.name},\n Imate 4 nepregledana sadržaja`,
    homeHeaderMessage_21: `Pozdrav ${variable?.name},\n Imate 21 nepregledan sadržaj`,
    homeHeaderMessage_22: `Pozdrav ${variable?.name},\n Imate 22 nepregledana sadržaja`,
    homeHeaderMessage_23: `Pozdrav ${variable?.name},\n Imate 23 nepregledana sadržaja`,
    homeHeaderMessage_24: `Pozdrav ${variable?.name},\n Imate 24 nepregledana sadržaja`,
    homeHeaderMessage: `Pozdrav ${variable?.name},\n Imate ${count} nepregledanih sadržaja`,
    finishedEducations: ` ${count} završenih edukacija`,
    finishedEducations_0: "Ni jedna završena edukacija",
    finishedEducations_1: "Jedna završena edukacija",
    finishedEducations_2: "Dvije završene edukacije",
    finishedEducations_3: "Tri završene edukacije",
    finishedEducations_4: "Četiri završene edukacije",
    finishedEducations_21: "21 završena edukacija",
    finishedEducations_22: "22 završene edukacije",
    finishedEducations_23: "23 završene edukacije",
    finishedEducations_24: "24 završene edukacije",
  },
  en: {
    homeHeaderMessage_0: `Pozdrav ${variable?.name},\n Nemate ni jedan nepregledan sadržaj`,
    homeHeaderMessage_1: `Pozdrav ${variable?.name},\n Imate 1 nepregledan sadržaj`,
    homeHeaderMessage_2: `Pozdrav ${variable?.name},\n Imate 2 nepregledana sadržaja`,
    homeHeaderMessage_3: `Pozdrav ${variable?.name},\n Imate 3 nepregledana sadržaja`,
    homeHeaderMessage_4: `Pozdrav ${variable?.name},\n Imate 4 nepregledana sadržaja`,
    homeHeaderMessage_21: `Pozdrav ${variable?.name},\n Imate 21 nepregledan sadržaj`,
    homeHeaderMessage_22: `Pozdrav ${variable?.name},\n Imate 22 nepregledana sadržaja`,
    homeHeaderMessage_23: `Pozdrav ${variable?.name},\n Imate 23 nepregledana sadržaja`,
    homeHeaderMessage_24: `Pozdrav ${variable?.name},\n Imate 24 nepregledana sadržaja`,
    homeHeaderMessage: `Pozdrav ${variable?.name},\n Imate ${count} nepregledanih sadržaja`,
    finishedEducations: ` ${count} završenih edukacija`,
    finishedEducations_0: "Ni jedna završena edukacija",
    finishedEducations_1: "Jedna završena edukacija",
    finishedEducations_2: "Dvije završene edukacije",
    finishedEducations_3: "Tri završene edukacije",
    finishedEducations_4: "Četiri završene edukacije",
    finishedEducations_21: "21 završena edukacija",
    finishedEducations_22: "22 završene edukacije",
    finishedEducations_23: "23 završene edukacije",
    finishedEducations_24: "24 završene edukacije",
  },
});

export const pluralize = ({
  lng,
  transKey,
  count,
  variable,
}: {
  lng: string;
  transKey: string;
  count?: number;
  variable?: any;
}) => {
  return (
    translations(variable, count)[lng][`${transKey}_${count}`] ||
    translations(variable, count)[lng][`${transKey}`] ||
    "undefined"
  );
};
