import { useMemo } from "react";
import { useQuery } from "react-query";
import { axiosInstance } from "../api/http";
import { useRecoilValue } from "recoil";
import { userIdAtom } from "../state/state";

export const useUserFormAttemptsByContent = (
  contentId: number,
  enabled: boolean
) => {
  const userId = useRecoilValue(userIdAtom);
  const storeKey = useMemo(
    () => ["user", userId, "content", contentId, "form-attempts"],
    [contentId, userId]
  );
  return useQuery(
    storeKey,
    () => axiosInstance.mobile.contentControllerGetContentAttempts(contentId),
    {
      enabled: !!userId && enabled && !!contentId,
      select: ({ data }) => data.payload,
    }
  );
};
