import { clearAllBodyScrollLocks, disableBodyScroll } from "body-scroll-lock";
import { useEffect, useRef } from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import styled from "styled-components";
import { ReactComponent as X } from "../assets/icons/x.svg";
import { useContentStats } from "../hooks/useContentStats";
import { useUser } from "../hooks/useUser";
import { tokenAtom } from "../state/state";
import Colors from "../utils/Colors";
import useWindowDimensions from "../utils/useWindowDimensions";
import { CustomText } from "./CustomText";
import { DashedProgress } from "./DashedProgress";
import { useUserProfileStats } from "../hooks/useUserProfileStats";
import { useContentCount } from "../hooks/useContentCount";
import { ReactComponent as EducationIcon } from "../assets/icons/content-type-icons/education.svg";
import { ReactComponent as InformaitonIcon } from "../assets/icons/content-type-icons/information.svg";
import { ReactComponent as InquiryIcon } from "../assets/icons/content-type-icons/inquiry.svg";
import { ReactComponent as PollIcon } from "../assets/icons/content-type-icons/poll.svg";
import { ReactComponent as TaskIcon } from "../assets/icons/content-type-icons/task.svg";
import { Spacer } from "./styled-components";

const ModalContainer = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
`;

const ProfileModalComponent = styled.div`
  position: absolute;
  width: ${isMobile ? "100%" : "300px"};
  top: ${isMobile ? "0" : "6%"};
  right: ${isMobile ? "0" : "calc((100vw - 1250px) / 2)"};
  background-color: #f6f6f6;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.15);
  border-radius: ${isMobile ? "0" : "8"}px;
  padding: 16px 0;
  z-index: 10;
  ${isMobile && "height: 100%;"};
`;

const ContentRow = styled.div<{
  hideBottomBorder?: boolean;
  hasSidePadding?: boolean;
}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 12px;
  padding-top: 12px;
  border-bottom: ${({ hideBottomBorder }) =>
    !hideBottomBorder ? `1px solid ${Colors.black10}` : "unset"};
  padding-left: ${({ hasSidePadding }) => (hasSidePadding ? 21 : 0)}px;
  padding-right: ${({ hasSidePadding }) => (hasSidePadding ? 21 : 0)}px;
`;

const Row = styled.div<{
  hideBottomBorder?: boolean;
  hasSidePadding?: boolean;
}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 12px;
  border-bottom: ${({ hideBottomBorder }) =>
    !hideBottomBorder ? `1px solid ${Colors.black10}` : "unset"};
  padding-left: ${({ hasSidePadding }) => (hasSidePadding ? 20 : 0)}px;
  padding-right: ${({ hasSidePadding }) => (hasSidePadding ? 20 : 0)}px;
`;

const HorizontalPadding = styled.div`
  padding: 0 21px;
`;

const CloseButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  border-radius: 20px;
  background-color: ${Colors.secondaryBackground};
  cursor: pointer;
`;

const ContentColumn = styled.div<{ hasBorder?: boolean; hasRank?: boolean }>`
  flex: 1;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-left: ${({ hasBorder }) =>
    hasBorder ? `1px solid ${Colors.black10}` : "unset"};
  border-right: ${({ hasBorder, hasRank }) =>
    hasBorder && hasRank ? `1px solid ${Colors.black10}` : "unset"};
`;

const iconsByType = {
  EDUCATION: EducationIcon,
  INFORMATION: InformaitonIcon,
  POLL: PollIcon,
  TASK: TaskIcon,
  INQUIRY: InquiryIcon,
};

export const ProfileModal = ({ closeModal }) => {
  const [, setToken] = useRecoilState(tokenAtom);
  const { data: userData } = useUser();
  const { data: stats } = useUserProfileStats();
  const { data: contentStats } = useContentStats();
  const { data: contentCount } = useContentCount();

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  const divRef = useRef(null);

  useEffect(() => {
    if (divRef.current) {
      disableBodyScroll(divRef.current);
    }
  }, []);

  return (
    <ModalContainer
      onClick={() => {
        clearAllBodyScrollLocks();
        closeModal();
      }}
    >
      <ProfileModalComponent
        onClick={(e) => e.stopPropagation()}
        id={"profileModal"}
        ref={divRef}
      >
        <Row hideBottomBorder>
          <HorizontalPadding>
            <CustomText
              fontSize={24}
              lineHeight={1.3}
              color={Colors.darkGray}
              fontWeight={500}
            >
              {userData?.name}
            </CustomText>
            <CustomText
              fontSize={13}
              lineHeight={1.3}
              color={Colors.darkGray80}
              fontWeight={400}
            >
              {userData?.email}
            </CustomText>
          </HorizontalPadding>
          {isMobile && (
            <CloseButtonContainer
              onClick={() => {
                clearAllBodyScrollLocks();
                closeModal();
              }}
            >
              <X />
            </CloseButtonContainer>
          )}
        </Row>
        <div style={{ backgroundColor: "white", paddingBottom: 16 }}>
          <ContentRow hideBottomBorder hasSidePadding={false}>
            <ContentColumn>
              <CustomText
                fontSize={13}
                lineHeight={1.3}
                color={Colors.gray80}
                fontWeight={500}
              >
                {t("accuracy")}
              </CustomText>
              <CustomText
                fontSize={28}
                lineHeight={1.3}
                color={Colors.darkGreen}
                fontWeight={400}
              >
                {stats?.accuracy || 0}%
              </CustomText>
            </ContentColumn>
            <ContentColumn hasBorder hasRank={stats?.rank || false}>
              <CustomText
                fontSize={13}
                lineHeight={1.3}
                color={Colors.gray80}
                fontWeight={500}
              >
                {t("points")}
              </CustomText>
              <CustomText
                fontSize={28}
                lineHeight={1.3}
                color={Colors.darkGreen}
                fontWeight={400}
              >
                {stats?.points || 0}
              </CustomText>
            </ContentColumn>
            {!!stats?.rank && (
              <ContentColumn>
                <CustomText
                  fontSize={13}
                  lineHeight={1.3}
                  color={Colors.gray80}
                  fontWeight={500}
                >
                  {t("rank")}
                </CustomText>
                <CustomText
                  fontSize={28}
                  lineHeight={1.3}
                  color={Colors.darkGreen}
                  fontWeight={400}
                >
                  {stats?.rank || 0}
                </CustomText>
              </ContentColumn>
            )}
          </ContentRow>
          <HorizontalPadding>
            <CustomText
              fontSize={18}
              lineHeight={1.3}
              color={Colors.darkGray}
              fontWeight={500}
              style={{ marginTop: 20, marginBottom: 14 }}
            >
              {t("activity")}
            </CustomText>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: 10,
              }}
            >
              <DashedProgress
                height={20}
                width={isMobile ? width - 40 : 220}
                percentage={
                  ((contentStats?.finishedContent || 0) /
                    (contentStats?.totalContent || 0)) *
                  100
                }
              />
              <CustomText
                fontSize={16}
                lineHeight={1.3}
                color={Colors.darkGreen}
                fontWeight={500}
              >
                {Math.round(
                  ((contentStats?.finishedContent || 0) /
                    (contentStats?.totalContent || 0)) *
                    100
                ) || 0}
                %
              </CustomText>
            </div>
            {(contentCount?.count || []).map((c, i, array) => {
              const Icon = iconsByType[c.type];
              return (
                <>
                  <div
                    key={c.type}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      borderBottomColor: "secondary.300",
                      borderBottomWidth: 1,
                      paddingTop: 10,
                      paddingBottom: 10,
                    }}
                  >
                    <div
                      style={{
                        rowGap: 9,
                        display: "flex",
                        alignItems: "center",
                        columnGap: 8,
                      }}
                    >
                      <Icon
                        width={24}
                        height={"24px"}
                        stroke={Colors.darkGreen}
                      />
                      <CustomText>
                        {t(c.type.toLowerCase()).toUpperCase()}
                      </CustomText>
                    </div>
                    <CustomText>{c.count}</CustomText>
                  </div>
                  {array.length - 1 !== i && <Spacer key={c.type + "spacer"} />}
                </>
              );
            })}
          </HorizontalPadding>
        </div>
        <ContentRow
          hideBottomBorder
          hasSidePadding={isMobile}
          style={{
            paddingBottom: 6,
            paddingTop: 24,
            paddingLeft: 21,
            paddingRight: 21,
          }}
        >
          <CustomText
            fontSize={16}
            lineHeight={1.3}
            style={{ textDecoration: "underline", cursor: "pointer" }}
            color={Colors.darkGreen}
            fontWeight={500}
            onClick={() => {
              clearAllBodyScrollLocks();
              setToken(null);
            }}
          >
            {t("logout")}
          </CustomText>
          <CustomText
            fontSize={16}
            lineHeight={1.3}
            style={{ textDecoration: "underline", cursor: "pointer" }}
            color={Colors.darkGreen}
            fontWeight={500}
            onClick={() => {
              navigate("change-password");
              clearAllBodyScrollLocks();
              closeModal();
            }}
          >
            {t("changePassword")}
          </CustomText>
        </ContentRow>
      </ProfileModalComponent>
    </ModalContainer>
  );
};
