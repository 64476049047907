import { useCallback, useEffect, useMemo, useState } from "react";
import { BrowserView, isMobile, MobileView } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import { useLocation } from "react-router-dom";
import { useRecoilState } from "recoil";
import styled from "styled-components";
import { ReactComponent as CheckMark } from "../../assets/icons/check.svg";
import { ReactComponent as Comment } from "../../assets/icons/comment.svg";
import { ReactComponent as Heart } from "../../assets/icons/heart.svg";
import { Button } from "../../components/Button";
import { CenteredCard } from "../../components/cards/CenteredCard";
import { CustomText } from "../../components/CustomText";
import { currentFormAtom, currentPageAtom } from "../../state/state";
import Colors from "../../utils/Colors";
import { UserAnswerDTO } from "../../new-api/api";
import { useContentDetails } from "../../hooks/useContentDetails";
import { useContentFunctions } from "../../hooks/useContentFunctions";

type ParamList = {
  contentId: number;
  answers: UserAnswerDTO[];
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${isMobile ? Colors.white : Colors.background};
  flex: 1;
`;

const Content = styled.div`
  max-width: 936px;
  width: 100%;
`;

const CustomMobileView = styled(MobileView)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 50px;
  text-align: center;
  padding-left: 20px;
  padding-right: 20px;
`;

const IconContainer = styled.div`
  width: 100px;
  height: 100px;
  background-color: ${Colors.background};
  margin-bottom: 30px;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  display: flex;
`;

export const FinishedPollPage = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { id } = useParams();
  const params = (state || {}) as ParamList;
  const { t } = useTranslation();
  const {
    markContentFinished: {
      mutateAsync: markContentFinished,
      isLoading: isMarkingContentFinished,
    },
    submitAnswers: {
      mutateAsync: submitAnswers,
      isLoading: isSubmittingAnswers,
    },
  } = useContentFunctions({
    contentId: params.contentId,
  });
  const { data } = useContentDetails({ contentId: params.contentId });
  const [showError] = useState(false);
  const [, setCurrentForm] = useRecoilState(currentFormAtom);
  const [, setCurrentPage] = useRecoilState(currentPageAtom);

  useEffect(() => {
    setCurrentForm([]);
    setCurrentPage(0);
  }, [setCurrentForm, setCurrentPage]);

  const handleGoToComments = useCallback(async () => {
    await submitAnswers({ questionAnswers: params.answers });
    navigate(`/content/${id}/comment`);
  }, [id, navigate, params.answers, submitAnswers]);

  const handleReadInfoContent = useCallback(async () => {
    const resp = await submitAnswers({
      questionAnswers: params.answers,
    });
    if (resp) {
      await markContentFinished();
      navigate("/");
    }
  }, [markContentFinished, navigate, params.answers, submitAnswers]);

  const innerContent = useMemo(
    () => (
      <>
        <IconContainer>
          <Heart />
        </IconContainer>
        <CustomText fontSize={24} fontWeight={500} color={Colors.darkGray}>
          {t("thanksForParticipation")}
        </CustomText>
        <CustomText
          fontSize={16}
          fontWeight={"normal"}
          color={Colors.gray}
          style={{ marginBottom: 20 }}
        >
          {t("appreciateEffort")}
        </CustomText>
        {data.settings.comments && (
          <Button
            text={t("sendComment")}
            style={{ marginBottom: 15, minWidth: 250 }}
            loading={isMarkingContentFinished || isSubmittingAnswers}
            onClick={handleGoToComments}
            buttonType="secondary"
            icon={Comment}
          />
        )}
        <Button
          text={t("ok")}
          loading={isMarkingContentFinished || isSubmittingAnswers}
          onClick={handleReadInfoContent}
          buttonType="primary"
          style={{ minWidth: 250 }}
          icon={CheckMark}
        />
        {showError && (
          <CustomText style={{ color: Colors.lightRed }}>
            {t("somethingWentWrong")}
          </CustomText>
        )}
      </>
    ),
    [
      data.settings.comments,
      handleGoToComments,
      handleReadInfoContent,
      isMarkingContentFinished,
      isSubmittingAnswers,
      showError,
      t,
    ]
  );

  return (
    <Container>
      <Content>
        <BrowserView renderWithFragment>
          <CenteredCard
            style={{ marginTop: 30, paddingTop: 130, paddingBottom: 130 }}
          >
            {innerContent}
          </CenteredCard>
        </BrowserView>

        <CustomMobileView>{innerContent}</CustomMobileView>
      </Content>
    </Container>
  );
};
