import { Circle } from "rc-progress";
import { useCallback, useEffect, useMemo, useState } from "react";
import { BrowserView, isMobile, MobileView } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import { useLocation } from "react-router-dom";
import { useRecoilState } from "recoil";
import styled from "styled-components";
import { ReactComponent as CheckMark } from "../../assets/icons/check.svg";
import { ReactComponent as Comment } from "../../assets/icons/comment.svg";
import { Button } from "../../components/Button";
import { CenteredCard } from "../../components/cards/CenteredCard";
import { CustomText } from "../../components/CustomText";
import { currentFormAtom, currentPageAtom } from "../../state/state";
import Colors from "../../utils/Colors";
import { UserAnswerDTO } from "../../new-api/api";
import { useContentFunctions } from "../../hooks/useContentFunctions";
import { useContentDetails } from "../../hooks/useContentDetails";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${!isMobile ? Colors.background : Colors.white};
  flex: 1;
`;

const Content = styled.div`
  max-width: 936px;
  width: 100%;
`;

const PercentageText = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 54.6274px;
  line-height: 130%;
  /* or 71px */
  position: absolute;
  top: 30%;
  left: 0;
  right: 0;
  text-align: center;
`;

const PercentText = styled.div`
  font-weight: 500;
  font-size: 26.0131px;
  line-height: 130%;
  /* or 71px */
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
`;

const CustomMobileView = styled(MobileView)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 50px;
`;

const RelativeContainer = styled.div`
  position: relative;
`;

type ParamList = {
  contentId: number;
  answers: UserAnswerDTO[];
  finalMark: number;
  passMark: number;
  numOfCorrect: number;
};

export const FinishedEducationPage = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { contentId, answers, finalMark, passMark, numOfCorrect } = (state ||
    {}) as ParamList;
  const { id } = useParams();
  const { refetch, data } = useContentDetails({ contentId });
  const {
    markContentFinished: {
      mutateAsync: markContentFinished,
      isLoading: isMarkingContentFinished,
    },
    submitAnswers: {
      mutateAsync: submitAnswers,
      isLoading: isSubmittingAnswers,
    },
  } = useContentFunctions({
    contentId,
  });
  const [progress, setProgress] = useState(0);
  const [showError] = useState(false);
  const [, setCurrentForm] = useRecoilState(currentFormAtom);
  const [, setCurrentPage] = useRecoilState(currentPageAtom);

  useEffect(() => {
    setCurrentForm([]);
    setCurrentPage(0);
  }, [setCurrentForm, setCurrentPage]);

  const { t } = useTranslation();

  useEffect(() => {
    setTimeout(() => {
      if (finalMark) {
        setProgress(finalMark * 100);
      }
    }, 500);
  }, [finalMark]);

  const hasPassed = finalMark >= passMark;

  const handleGoNext = useCallback(async () => {
    await submitAnswers({
      questionAnswers: answers,
    });

    if (!hasPassed) {
      await refetch();
      //@ts-ignore
      navigate(-2);
    } else {
      await markContentFinished();
      //@ts-ignore
      navigate("/");
    }
  }, [
    submitAnswers,
    answers,
    hasPassed,
    refetch,
    navigate,
    markContentFinished,
  ]);

  const innerContent = useMemo(
    () => (
      <>
        <RelativeContainer>
          <Circle
            percent={progress}
            strokeWidth={6}
            trailWidth={6}
            trailColor={Colors.backgroundGray}
            strokeColor={hasPassed ? Colors.primaryGreen : Colors.lightRed}
            gapPosition="bottom"
            gapDegree={90}
            style={{ width: 200 }}
            strokeLinecap="square"
          />
          <PercentageText>{progress.toFixed(0)}</PercentageText>
          <PercentText>%</PercentText>
        </RelativeContainer>
        <CustomText fontSize={24} fontWeight={500} color={Colors.darkGray}>
          {hasPassed ? t("educationPassed") : t("educationFailed")}
        </CustomText>
        <CustomText
          fontSize={16}
          fontWeight={400}
          color={Colors.gray}
          style={{ marginBottom: 40 }}
        >
          {t("correctAnswers", {
            total: answers.length || 0,
            correct: numOfCorrect || 0,
          })}
        </CustomText>
        {hasPassed && (
          <CustomText
            fontSize={16}
            fontWeight={400}
            color={Colors.gray}
            style={{ marginBottom: 20 }}
          >
            {t("educationWillBeSaved")}
          </CustomText>
        )}
        {data?.settings?.comments && (
          <Button
            text={t("sendComment")}
            loading={isMarkingContentFinished || isSubmittingAnswers}
            onClick={() => navigate(`/content/${id}/comment`)}
            buttonType="secondary"
            style={{ marginBottom: 15, minWidth: 250 }}
            icon={Comment}
          />
        )}
        <Button
          text="OK"
          onClick={handleGoNext}
          loading={isMarkingContentFinished || isSubmittingAnswers}
          buttonType="primary"
          style={{ marginBottom: 15, minWidth: 250 }}
          icon={CheckMark}
        />
        {showError && (
          <CustomText style={{ color: Colors.lightRed }}>
            {t("somethingWentWrong")}
          </CustomText>
        )}
      </>
    ),
    [
      answers.length,
      data?.settings?.comments,
      handleGoNext,
      hasPassed,
      id,
      isMarkingContentFinished,
      isSubmittingAnswers,
      navigate,
      numOfCorrect,
      progress,
      showError,
      t,
    ]
  );

  return (
    <Container>
      <Content>
        <BrowserView renderWithFragment>
          <CenteredCard
            style={{ marginTop: 30, paddingTop: 130, paddingBottom: 130 }}
          >
            {innerContent}
          </CenteredCard>
        </BrowserView>
        <CustomMobileView>{innerContent}</CustomMobileView>
      </Content>
    </Container>
  );
};
