import { CustomText } from "./CustomText";
import Collapsible from "react-collapsible";
import SlidingPane from "react-sliding-pane";
import { memo, useEffect, useState } from "react";
import { useForm, UseFormRegister } from "react-hook-form";
import { CheckboxInput } from "./CheckboxInput";
import styled from "styled-components";
import Colors from "../utils/Colors";
import { useFinishedContentFilters } from "../hooks/useFinishedContentFilters";
import { ReactComponent as XIcon } from "../assets/icons/thinX.svg";
import { ReactComponent as ChevronBottom } from "../assets/icons/ChevronBottom.svg";
import { useTranslation } from "react-i18next";
import "react-sliding-pane/dist/react-sliding-pane.css";
import { ButtonContainer } from "./styled-components";
import { Button } from "./Button";
import "./FilterSidePane.css";
import { getAllItemsFromIndexes } from "../utils/shared";
import { useRecoilState } from "recoil";
import { archivePageAtom, filtersAtom } from "../state/state";
const HeaderText = styled(CustomText)``;

const HeaderRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
  margin-bottom: 15px;
`;

const FilterRow = styled.div<{ hasBorder: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 16px;
  padding-bottom: 16px;
  cursor: pointer;
  border-bottom: 1px solid
    ${({ hasBorder }) => (hasBorder ? Colors.border : "transparent")};
`;

const CheckboxRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 4px;
`;

const FilterRowComponent = memo(
  ({
    title,
    value,
    register,
    name,
  }: {
    title: string;
    value: string;
    register: UseFormRegister<any>;
    name: string;
  }) => {
    return (
      <CheckboxRow>
        <CheckboxInput
          register={register}
          name={name}
          label={title}
          // options={{ value }}
        />
      </CheckboxRow>
    );
  }
);

interface IFormInput {
  contentTypes?: boolean[];
  creators?: boolean[];
  categories?: boolean[];
  periods?: boolean[];
}
export const FilterSidePane = ({
  openPanel,
  setOpenPanel,
}: {
  openPanel: boolean;
  setOpenPanel: (val: boolean) => void;
}) => {
  const [filters, setFilters] = useRecoilState(filtersAtom);
  const [openAccordionPosition, setOpenAccordionPosition] = useState<
    number | null
  >(null);
  const [currentPage, setCurrentPage] = useRecoilState(archivePageAtom);

  const [hasSetDefaultValues, setHasSetDefaultValues] = useState(false);
  const { data: finishedContentFilters } = useFinishedContentFilters();
  const { t } = useTranslation();
  const { register, handleSubmit, setValue } = useForm<IFormInput>({
    defaultValues: {
      categories: [],
      contentTypes: [],
      creators: [],
      periods: [],
    },
    shouldFocusError: true,
  });

  useEffect(() => {
    if (!!finishedContentFilters) {
      setValue(
        "categories",
        (finishedContentFilters?.categories || []).map((cat) =>
          filters.categories.includes(cat.value)
        )
      );
      setValue(
        "periods",
        (finishedContentFilters?.periods || []).map((cat) =>
          filters.periods.includes(cat.value)
        )
      );
      setValue(
        "creators",
        (finishedContentFilters?.creators || []).map((cat) =>
          filters.creators.includes(cat.value)
        )
      );
      setValue(
        "contentTypes",
        (finishedContentFilters?.contentTypes || []).map((cat) =>
          filters.contentTypes.includes(cat.value)
        )
      );
      setHasSetDefaultValues(true);
    }
  }, [
    filters.categories,
    filters.contentTypes,
    filters.creators,
    filters.periods,
    finishedContentFilters,
    setValue,
  ]);

  const onSubmit = handleSubmit((val) => {
    const contentTypeIndexes = getAllItemsFromIndexes(
      val.contentTypes,
      true,
      finishedContentFilters.contentTypes
    )
      .map((c) => c.value)
      .join(",");
    const categoriesIndexes = getAllItemsFromIndexes(
      val.categories,
      true,
      finishedContentFilters.categories
    )
      .map((c) => c.value)
      .join(",");
    const creatorsIndexes = getAllItemsFromIndexes(
      val.creators,
      true,
      finishedContentFilters.creators
    )
      .map((c) => c.value)
      .join(",");
    const periodsIndexes = getAllItemsFromIndexes(
      val.periods,
      true,
      finishedContentFilters.periods
    )
      .map((c) => c.value)
      .join(",");
    setCurrentPage(0);
    setFilters({
      creators: creatorsIndexes,
      periods: periodsIndexes,
      contentTypes: contentTypeIndexes,
      categories: categoriesIndexes,
    });
    setOpenAccordionPosition(null);
    setOpenPanel(false);
  });

  const handleTriggerClick = (accordionPosition: number) => {
    setOpenAccordionPosition((prev) =>
      prev === accordionPosition ? null : Number(accordionPosition)
    );
  };
  return (
    <SlidingPane
      width={"35%"}
      shouldCloseOnEsc
      isOpen={openPanel}
      hideHeader
      onRequestClose={() => {
        setOpenPanel(false);
      }}
    >
      <div>
        <HeaderRow>
          <CustomText fontSize={24} fontWeight={300}>
            {t("contentFilter")}
          </CustomText>
          <div
            style={{ cursor: "pointer" }}
            onClick={() => setOpenPanel(false)}
          >
            <XIcon width={32} height={32} />
          </div>
        </HeaderRow>
        {(finishedContentFilters?.contentTypes || []).length > 0 && (
          <Collapsible
            handleTriggerClick={handleTriggerClick}
            open={openAccordionPosition === 1}
            accordionPosition={1}
            trigger={
              <FilterRow hasBorder={openAccordionPosition !== 1}>
                <HeaderText>{t("contentType")}</HeaderText>
                <ChevronBottom
                  style={{
                    rotate: openAccordionPosition === 1 ? "180deg" : "0deg",
                    transition: "all 0.5s",
                  }}
                />
              </FilterRow>
            }
            transitionTime={200}
          >
            {finishedContentFilters?.contentTypes.map((ct, i) => {
              return (
                <FilterRowComponent
                  key={ct.value}
                  title={t(ct.label)}
                  value={ct.value}
                  name={`contentTypes.${i}`}
                  register={register}
                />
              );
            })}
          </Collapsible>
        )}
        {(finishedContentFilters?.creators || []).length > 0 && (
          <Collapsible
            open={openAccordionPosition === 2}
            accordionPosition={2}
            handleTriggerClick={handleTriggerClick}
            trigger={
              <FilterRow hasBorder={openAccordionPosition !== 2}>
                <HeaderText>{t("sender")}</HeaderText>
                <ChevronBottom
                  style={{
                    rotate: openAccordionPosition === 2 ? "180deg" : "0deg",
                    transition: "all 0.5s",
                  }}
                />
              </FilterRow>
            }
            transitionTime={200}
          >
            {finishedContentFilters?.creators.map((ct, i) => {
              return (
                <FilterRowComponent
                  key={ct.value}
                  title={t(ct.label)}
                  value={ct.value}
                  name={`creators.${i}`}
                  register={register}
                />
              );
            })}
          </Collapsible>
        )}
        {(finishedContentFilters?.categories || []).length > 0 && (
          <Collapsible
            open={openAccordionPosition === 3}
            accordionPosition={3}
            handleTriggerClick={handleTriggerClick}
            trigger={
              <FilterRow hasBorder={openAccordionPosition !== 3}>
                <HeaderText>{t("category")}</HeaderText>
                <ChevronBottom
                  style={{
                    rotate: openAccordionPosition === 3 ? "180deg" : "0deg",
                    transition: "all 0.5s",
                  }}
                />
              </FilterRow>
            }
            transitionTime={200}
          >
            {finishedContentFilters?.categories.map((ct, i) => {
              return (
                <FilterRowComponent
                  key={ct.value}
                  title={t(ct.label)}
                  value={ct.value}
                  name={`categories.${i}`}
                  register={register}
                />
              );
            })}
          </Collapsible>
        )}
        {(finishedContentFilters?.periods || []).length > 0 && (
          <Collapsible
            open={openAccordionPosition === 4}
            accordionPosition={4}
            handleTriggerClick={handleTriggerClick}
            trigger={
              <FilterRow hasBorder={openAccordionPosition !== 4}>
                <HeaderText>{t("timePeriod")}</HeaderText>
                <ChevronBottom
                  style={{
                    rotate: openAccordionPosition === 4 ? "180deg" : "0deg",
                    transition: "all 0.5s",
                  }}
                />
              </FilterRow>
            }
            transitionTime={200}
          >
            {finishedContentFilters?.periods.map((ct, i) => {
              return (
                <FilterRowComponent
                  key={ct.value}
                  title={t(ct.label)}
                  value={ct.value}
                  name={`periods.${i}`}
                  register={register}
                />
              );
            })}
          </Collapsible>
        )}
      </div>
      <ButtonContainer style={{ justifyContent: "flex-end" }}>
        <Button
          text={t("cancelSelection")}
          buttonType={"secondary"}
          icon={null}
          onClick={() => {
            setCurrentPage(0);
            setOpenAccordionPosition(null);
            setFilters({
              creators: "",
              categories: "",
              periods: "",
              contentTypes: "",
            });
            setOpenPanel(false);
          }}
        />
        <Button
          text={t("confirmSelection")}
          buttonType={"primary"}
          onClick={onSubmit}
        />
      </ButtonContainer>
    </SlidingPane>
  );
};
