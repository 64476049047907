export const BASE_URL = "https://beta-api.etenim.app";
// export const BASE_URL = "http://192.168.0.8:3000";

export const ITEMS_PER_PAGE = 3;

export function arraysHaveSameNumbers(arr1: number[], arr2: number[]) {
  // Check if the arrays have the same length
  if (arr1.length !== arr2.length) {
    return false;
  }

  // Sort the arrays
  const sortedArr1 = arr1.slice().sort();
  const sortedArr2 = arr2.slice().sort();

  // Compare the sorted arrays element by element
  for (let i = 0; i < sortedArr1.length; i++) {
    if (sortedArr1[i] !== sortedArr2[i]) {
      return false;
    }
  }

  return true;
}

export function getAllItemsFromIndexes<T>(
  arr: any[],
  val: any,
  itemArr: T[]
): T[] {
  let i = -1,
    items = [];
  while ((i = arr.indexOf(val, i + 1)) !== -1) {
    items.push(itemArr[i]);
  }
  return items;
}
