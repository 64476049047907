import moment from "moment";
import { FC, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Colors from "../utils/Colors";
import { CustomText } from "./CustomText";

import {
  FinishedContentDto,
  FinishedContentDtoTypeEnum,
  NewContentWithAttempts,
  NewContentWithAttemptsConfigurationEnum,
  NewContentWithAttemptsTypeEnum,
} from "../new-api/api";
import { useTranslation } from "react-i18next";
import { Button } from "./Button";
import { DashedProgress } from "./DashedProgress";
import { isMobile } from "react-device-detect";
import useWindowDimensions from "../utils/useWindowDimensions";
import { arraysHaveSameNumbers } from "../utils/shared";
import { useContentAttempts } from "../hooks/useContentAttempts";
import { useContentQuestionsAndAnswers } from "../hooks/useContentQuestionsAndAnswers";

const ItemRowComponent = styled.div`
  display: flex;
  padding: 24px;
  min-height: 160px;
`;

const ItemDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
`;

const PromoChip = styled.div`
  padding: 1px 4px;
  background-color: ${Colors.lightRed};
  border-radius: 4px;
  font-family: Archivo;
  font-size: 13px;
  color: white;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
  text-decoration: none;
`;

const MobileHorizontalPadding = styled.div`
  margin-top: 16px;
  padding-left: ${isMobile ? 20 : 0}px;
  padding-right: ${isMobile ? 20 : 0}px;
`;

const PaddedRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 16px;
  padding-top: 1px;
`;

interface Props {
  item: NewContentWithAttempts | FinishedContentDto;
  index?: number;
  shouldGoToAnswers?: boolean;
  navigateTo?: string;
}

export const ArchiveItemRow: FC<Props> = ({ navigateTo = "/", item }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isTask =
    item?.type === NewContentWithAttemptsTypeEnum.TASK ||
    item?.type === FinishedContentDtoTypeEnum.TASK;
  const { width } = useWindowDimensions();

  const { data: contentQuestions } = useContentQuestionsAndAnswers(
    item.id,
    item.type === FinishedContentDtoTypeEnum.EDUCATION
  );
  const { data } = useContentAttempts({
    contentId: item.id,
    enabled: item.type === FinishedContentDtoTypeEnum.EDUCATION,
  });

  const attempt =
    item?.type === NewContentWithAttemptsTypeEnum.EDUCATION &&
    data?.attempts[0];

  const numOfCorrect = useMemo(
    () =>
      (contentQuestions?.questions || []).reduce(
        (previousValue, currentValue) => {
          const answeredQuestion = (attempt?.userAnswers || []).find(
            (qa) => qa.question.id === currentValue.id
          );
          const correctAnswers = currentValue.answers
            .filter((ans) => ans.isCorrect)
            .map((ans) => ans.id);
          return arraysHaveSameNumbers(
            correctAnswers,
            (answeredQuestion?.answers || []).map((aq) => aq.id)
          )
            ? previousValue + 1
            : previousValue;
        },
        0
      ),
    [attempt?.userAnswers, contentQuestions?.questions]
  );

  return (
    <ItemRowComponent>
      <ItemDataContainer>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: 10,
          }}
        >
          {item.configuration ===
          NewContentWithAttemptsConfigurationEnum.PROMOTION ? (
            <PromoChip>Promo</PromoChip>
          ) : (
            <CustomText
              fontSize={14}
              lineHeight={1}
              uppercase={true}
              color={Colors.gray100}
            >
              {t(item.type)}
            </CustomText>
          )}
          {/*@ts-ignore*/}
          {item?.contentInteractions?.[0]?.finishedAt && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                columnGap: 4,
              }}
            >
              <CustomText color={Colors.gray} fontSize={14}>
                {t("seen")}:
              </CustomText>
              <CustomText
                color={Colors.darkGray}
                fontWeight={500}
                fontSize={14}
              >
                {/*@ts-ignore*/}
                {moment(item?.contentInteractions?.[0]?.finishedAt).format(
                  "DD.MM.YYYY."
                )}
              </CustomText>
            </div>
          )}
        </div>

        <CustomText
          fontSize={20}
          lineHeight={1}
          fontWeight={500}
          color={Colors.darkGray}
        >
          {item.name}
        </CustomText>
        {!isTask && !!item.description && (
          <CustomText
            style={{
              maxHeight: "2.5em",
              overflow: "hidden",
              lineHeight: "1.25em",
              marginTop: 10,
            }}
            fontSize={14}
            lineHeight={1.4}
            color={Colors.darkGray}
          >
            {item.description}
          </CustomText>
        )}
        {item.type === NewContentWithAttemptsTypeEnum.EDUCATION && !!attempt && (
          <MobileHorizontalPadding>
            <DashedProgress
              height={10}
              width={isMobile ? width - 40 : 890}
              percentage={attempt.finalMark * 100}
            />
            <PaddedRow>
              <CustomText
                fontSize={16}
                lineHeight={1.3}
                color={Colors.darkGreen}
                fontWeight={500}
              >
                {numOfCorrect}/{(contentQuestions?.questions || []).length}
              </CustomText>
              <CustomText
                fontSize={16}
                lineHeight={1.3}
                color={Colors.darkGreen}
                fontWeight={500}
              >
                {(attempt.finalMark * 100).toFixed(0)}%
              </CustomText>
            </PaddedRow>
          </MobileHorizontalPadding>
        )}
        <div>
          <Button
            text={t("open")}
            buttonType={"primary"}
            onClick={() => navigate(navigateTo)}
          />
        </div>
      </ItemDataContainer>
    </ItemRowComponent>
  );
};
