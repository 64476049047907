import styled from "styled-components";
import Colors from "../utils/Colors";
import { ReactComponent as ArrowRight } from "../assets/icons/ArrowRight.svg";
import { Ring } from "@uiball/loaders";

const ButtonComponent = styled.button<{
  buttonType: "primary" | "secondary" | "disabled";
  loading: boolean;
  disabled: boolean;
}>`
  display: flex;
  padding: 12px 30px;
  border-radius: 30px;
  flex-direction: row;
  align-items: center;
  justify-content: ${({ loading }) => (loading ? "center" : "space-between")};
  border-width: 2px;
  border-style: solid;
  border-color: ${({ disabled }) =>
    disabled ? Colors.spacerGray : Colors.primaryGreen};
  cursor: pointer;
  font-family: Archivo;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  text-decoration: none;
  color: ${Colors.darkGreen};
  text-align: left;
  background-color: ${({ disabled, buttonType }) =>
    disabled
      ? Colors.spacerGray
      : buttonType === "secondary"
      ? Colors.white
      : Colors.primaryGreen};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
`;

interface IButtonProps {
  onClick?(): void;
  text: string;
  buttonType: "primary" | "disabled" | "secondary";
  type?: "button" | "submit" | "reset";
  icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  loading?: boolean;
  disabled?: boolean;
  disabledHoverText?: string;
  style?: any;
}

export const Button = (props: IButtonProps) => {
  const {
    onClick,
    text,
    buttonType,
    icon: Icon = ArrowRight,
    type = "button",
    loading,
    disabledHoverText,
    disabled,
    style,
  } = props;

  return (
    <ButtonComponent
      onClick={disabled ? () => {} : onClick}
      buttonType={buttonType}
      type={type}
      loading={loading}
      title={disabled ? disabledHoverText : undefined}
      disabled={disabled}
      style={style}
    >
      {loading ? (
        <Ring
          size={16}
          color={
            buttonType === "secondary" ? Colors.primaryGreen : Colors.white
          }
        />
      ) : (
        <>
          {!!text && text}
          {!!Icon && (
            <Icon
              fillOpacity={disabled ? 0.5 : 1}
              style={{ marginLeft: !!text ? 8 : 0 }}
            />
          )}
        </>
      )}
    </ButtonComponent>
  );
};
