import { RegisterOptions, UseFormRegister } from "react-hook-form";
import styled from "styled-components";
import Colors from "../utils/Colors";
import { CustomText } from "./CustomText";

const InputContainer = styled.div<{ error: boolean }>`
  display: flex;
  align-items: center;
  input[type="checkbox"] {
    accent-color: ${Colors.primaryGreen};
  }
`;

interface ITextInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  register: UseFormRegister<any>;
  name: string;
  label?: string;
  options?: RegisterOptions;
  error?: string;
}

export const CheckboxInput = ({
  register,
  name,
  options,
  label,
  error,
  ...rest
}: ITextInputProps) => {
  return (
    <>
      <InputContainer error={!!error}>
        <input type="checkbox" {...register(name, options)} {...rest} />
        {!!label && (
          <CustomText style={{ color: Colors.darkGray, marginLeft: 10 }}>
            {label}
          </CustomText>
        )}
      </InputContainer>
      {!!error && (
        <CustomText
          fontSize={13}
          fontWeight={400}
          style={{ position: "absolute", color: Colors.progressRed }}
        >
          {error}
        </CustomText>
      )}
    </>
  );
};
