import { useMemo } from "react";
import { useQuery } from "react-query";
import { axiosInstance } from "../api/http";
import { useRecoilValue } from "recoil";
import { userIdAtom } from "../state/state";

export const useContentStats = () => {
  const userId = useRecoilValue(userIdAtom);
  const storeKey = useMemo(() => ["user", userId, "content-stats"], [userId]);
  return useQuery(
    storeKey,
    axiosInstance.mobile.contentControllerGetContentStats,
    {
      enabled: !!userId,
      select: ({ data }) => data.payload,
    }
  );
};
