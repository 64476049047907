import * as React from "react";
import { useCallback, useState } from "react";
import { isMobile } from "react-device-detect";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { ReactComponent as Check } from "../../assets/icons/check.svg";
import { ReactComponent as Heart } from "../../assets/icons/heart.svg";
import { Button } from "../../components/Button";
import { CenteredCard } from "../../components/cards/CenteredCard";
import { CustomText } from "../../components/CustomText";
import { Container, Content } from "../../components/styled-components";
import Colors from "../../utils/Colors";
import { useContentFunctions } from "../../hooks/useContentFunctions";
import { BackButton } from "../../components/BackButton";

const ErrorText = styled.div`
  color: ${Colors.lightRed};
  margin-bottom: 20px;
`;

const InnerContent = styled.div`
  max-width: 765px;
  width: 100%;
`;

const IconContainer = styled.div`
  width: 100px;
  height: 100px;
  background-color: ${Colors.background};
  margin-bottom: 30px;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  display: flex;
`;

const CustomTextArea = styled.textarea`
  margin-top: 20px;
  width: calc(100% - 40px);
  height: ${isMobile ? 110 : 200}px;
  border: 1px solid ${Colors.black10};
  border-radius: 4px;
  font-family: Archivo;
  font-size: 16px;
  padding: 20px;

  &:focus {
    outline: none !important;
    border: 1px solid ${Colors.primaryGreen};
  }
`;

const CenteredDiv = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  column-gap: 20px;
`;

type FormValues = {
  comment: string;
};

export const CommentPage = () => {
  const { t } = useTranslation();
  const { id } = useParams();

  const [hasSentComment, setHasSentComment] = useState(false);
  const {
    commentContent: {
      mutateAsync: commentContent,
      isLoading: isCommentingContent,
      isError,
      error,
    },
  } = useContentFunctions({
    contentId: Number(id),
  });
  const { handleSubmit, setValue } = useForm<FormValues>({
    shouldFocusError: true,
    reValidateMode: "onBlur",
    defaultValues: {
      comment: "",
    },
  });

  const navigate = useNavigate();

  const goBack = useCallback(() => navigate(-1), [navigate]);

  React.useEffect(() => {
    if (hasSentComment) {
      setTimeout(() => {
        goBack();
      }, 2000);
    }
  }, [goBack, hasSentComment]);

  const onSubmit = handleSubmit(async (values) => {
    if (values.comment.length > 0) {
      await commentContent({
        comment: values.comment,
      });
      setHasSentComment(true);
    }
  });

  return (
    <Container>
      <Content>
        <CenteredCard
          style={{
            display: "flex",
            marginTop: 30,
            justifyContent: "center",
          }}
        >
          <InnerContent>
            {!hasSentComment ? (
              <form onSubmit={onSubmit}>
                <CustomText
                  fontSize={24}
                  fontWeight={500}
                  color={Colors.darkGray}
                >
                  {t("sendComment")}
                </CustomText>
                <CustomText
                  fontSize={16}
                  fontWeight={"normal"}
                  color={Colors.gray}
                >
                  {t("sendCommentDesc")}
                </CustomText>
                <div
                  style={{
                    marginBottom: isMobile ? 20 : 0,
                    paddingLeft: isMobile ? 20 : 0,
                    paddingRight: isMobile ? 20 : 0,
                  }}
                >
                  <CustomTextArea
                    id="w3review"
                    onChange={(event) =>
                      setValue("comment", event.target.value)
                    }
                    autoFocus={!isMobile}
                    style={{ marginBottom: 20 }}
                  />
                </div>
                {isError && (
                  // @ts-ignore
                  <ErrorText>{error?.response?.data?.message}</ErrorText>
                )}
                <Row>
                  <BackButton />
                  <Button
                    text={t("send")}
                    buttonType="primary"
                    type="submit"
                    disabled={isCommentingContent}
                    loading={isCommentingContent}
                    icon={Check}
                    style={{ minWidth: 250 }}
                  />
                </Row>
              </form>
            ) : (
              <CenteredDiv>
                <IconContainer>
                  <Heart />
                </IconContainer>
                <CustomText
                  fontSize={24}
                  fontWeight={500}
                  color={Colors.darkGray}
                >
                  {t("thanksForComment")}
                </CustomText>
                <CustomText
                  fontSize={16}
                  fontWeight={"normal"}
                  color={Colors.gray}
                  style={{ marginBottom: 20 }}
                >
                  {t("appreciateEffort")}
                </CustomText>
              </CenteredDiv>
            )}
          </InnerContent>
        </CenteredCard>
      </Content>
    </Container>
  );
};
