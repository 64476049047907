import { RegisterOptions, UseFormRegister } from "react-hook-form";
import styled from "styled-components";
import Colors from "../utils/Colors";
import { CustomText } from "./CustomText";

const InputContainer = styled.div<{ error: boolean }>`
  border: 1px solid ${({ error }) => (error ? Colors.lightRed : Colors.black10)};
  border-radius: 4px;
  position: relative;
  margin-bottom: 24px;
  input {
    border: 0;
    width: 85%;
    border-radius: 4px;
    padding: 15px;
  }

  textarea:focus,
  input:focus {
    outline: none;
  }
`;

const RightIconContainer = styled.div`
  position: absolute;
  width: 30px;
  height: 30px;
  right: 10px;
  top: 8px;
`;

interface ITextInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  register: UseFormRegister<any>;
  name: string;
  label?: string;
  options: RegisterOptions;
  error?: string;
  rightIcon?: any;
}

export const TextInput = ({
  register,
  name,
  options,
  label,
  error,
  rightIcon,
  ...rest
}: ITextInputProps) => {
  return (
    <>
      {!!label && (
        <CustomText style={{ color: Colors.darkGray }}>{label}</CustomText>
      )}
      <InputContainer error={!!error}>
        <input {...register(name, options)} {...rest} />
        {rightIcon && <RightIconContainer>{rightIcon}</RightIconContainer>}
        {!!error && (
          <CustomText
            fontSize={13}
            fontWeight={400}
            style={{ position: "absolute", color: Colors.progressRed }}
          >
            {error}
          </CustomText>
        )}
      </InputContainer>
    </>
  );
};
