import { useMemo } from "react";
import { useQuery } from "react-query";
import { axiosInstance } from "../api/http";
import { useRecoilValue } from "recoil";
import { userIdAtom } from "../state/state";

export const useContentQuestionsAndAnswers = (
  contentId: number,
  enabled: boolean
) => {
  const storeKey = useMemo(
    () => ["content", contentId, "questions-answers"],
    [contentId]
  );
  const userId = useRecoilValue(userIdAtom);

  return useQuery(
    storeKey,
    () =>
      axiosInstance.mobile.contentControllerGetContentQuestionsWithAnswers(
        contentId
      ),
    {
      enabled: !!userId && !!contentId && enabled,
      select: ({ data }) => data.payload,
    }
  );
};
