export const SliderArrow = (props) => {
  const { className, style, onClick, type } = props;
  const isDisabled = (className as string).includes("disabled");
  if (isDisabled) {
    return null;
  }
  return (
    <div
      className={`${className} ${
        type === "left" ? "arrow-prev" : "arrow-next"
      } `}
      style={{
        ...style,
        display: "flex",
        alignItems: "center",
        zIndex: 100000,
        justifyContent: "center",
        background: isDisabled ? "rgba(0, 0, 0, 0.1)" : "rgba(0, 0, 0, 0.3)",
        width: 40,
        height: 40,
        right: type === "right" ? -20 : "unset",
        left: type === "left" ? -20 : "unset",
        borderRadius: 20,
      }}
      onClick={onClick}
    />
  );
};
