// eslint-disable-next-line import/no-anonymous-default-export
export default {
  backgroundGray: "#E5E5E5",
  spacerGray: "#F0EFED",
  black: "#000000",
  darkGray: "#2C2C2C",
  darkGray80: "#2C2C2CCC",
  darkGreen: "#2D5049",
  darkGreen50: "#2D504980",
  darkGreen80: "#2D5049CC",
  primary: "#2D5049",
  text: "#2D504980",
  border: "#DEDEDE",
  placeholder: "#ffffff97",
  disabled: "#CCCCCC",
  background: "#F9FBFD",
  card: "#F6F6F6",
  white: "#FFFFFF",
  white80: "#FFFFFFCC",
  transparentWhite: "#FFFFFF9f",
  secondaryBackground: "#FFFFFF",
  gray: "#999999",
  gray100: "#7C7C7C",
  gray80: "#999999CC",
  lighterGray: "#F1F4F5",
  notification: "#FFFFFFDE",
  lightGray: "#D7D9E0",
  transparent: "rgba(0,0,0,0)",
  black10: "rgba(0,0,0,0.1)",
  progressRed: "#FF8080",
  red: "#FC021A",
  lightRed: "#FFA5B5",
  progressYellow: "#FED049",
  primaryGreen: "#59EEB9",
  primaryGreen07: "#52DD9112",
};
