import { Api } from "../new-api/api";
import { BASE_URL } from "../utils/shared";
import { tokenAtom } from "../state/state";
import { getRecoil } from "recoil-nexus";

export const axiosInstance = new Api({
  baseURL: BASE_URL,
  responseType: 'json'
});

axiosInstance.instance.interceptors.request.use(function (config) {
  const token = getRecoil(tokenAtom)
  config.headers.Authorization = `Bearer ${token}`;

  return config;
});
