import React, { FC } from "react";
import { CSSTransition } from "react-transition-group"; // ES6
import styled from "styled-components";
import { ReactComponent as CorrectSolid } from "../assets/icons/correct-solid.svg";
import { ReactComponent as Selected } from "../assets/icons/selected-outline.svg";
import { ReactComponent as Wrong } from "../assets/icons/wrong.svg";
import Colors from "../utils/Colors";
import { CustomText } from "./CustomText";
import "./FormAnswerRow.css";
import { AnswerDto } from "../new-api/api";

const Circle = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 12px;
  margin-right: 10px;
  background-color: ${Colors.background};
`;

const WrongCircle = styled(Wrong)`
  width: 24px;
  height: 24px;
  border-radius: 12px;
  margin-right: 10px;
`;

const CorrectSolidCircle = styled(CorrectSolid)`
  width: 24px;
  height: 24px;
  border-radius: 12px;
  margin-right: 10px;
`;

const SelectedCircle = styled(Selected)`
  width: 24px;
  height: 24px;
  border-radius: 12px;
  margin-right: 10px;
`;

const AnswerRowContainer = styled.div<{
  disabled?: boolean;
  isSelected?: boolean;
  isCorrect?: boolean;
}>`
  padding: 20px 30px;
  border-top: 1px solid transparent;
  border-bottom: 1px solid ${Colors.black10};
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  transition: all 0.5s ease;

  ${({ isSelected }) =>
    isSelected &&
    `
    padding-left: 30px;
    padding-right: 30px;
    margin-left: 0px;
    margin-right: 0px;
    background-color: ${Colors.primaryGreen07};
    border-bottom: 1px solid ${Colors.primaryGreen};
    border-top: 1px solid ${Colors.primaryGreen};
  `}
`;

interface Props {
  answer: AnswerDto;
  onPress(): void;
  isSelected?: boolean;
  showIfCorrect?: boolean;
}

export const FormAnswerRow: FC<Props> = React.memo(
  ({ answer, onPress, isSelected, showIfCorrect }) => {
    return (
      <AnswerRowContainer
        onClick={showIfCorrect ? () => {} : onPress}
        disabled={showIfCorrect}
        isSelected={isSelected}
      >
        <CSSTransition
          in={showIfCorrect && isSelected}
          timeout={500}
          classNames="example"
        >
          {showIfCorrect ? (
            <>
              {!answer.isCorrect && isSelected ? (
                <WrongCircle id="WrongCircle" />
              ) : answer.isCorrect && isSelected ? (
                <CorrectSolidCircle id="CorrectSolidCircle" />
              ) : (
                <Circle id="Circle1" />
              )}
            </>
          ) : (
            <>
              {isSelected ? (
                <SelectedCircle id="SelectedCircle" />
              ) : (
                <Circle id="Circle2" />
              )}
            </>
          )}
        </CSSTransition>
        <CustomText>{answer?.title}</CustomText>
      </AnswerRowContainer>
    );
  }
);
