import styled from "styled-components";

const CardContainer = styled.div`
  border-radius: 6px;
  padding: 24px 36px;
  background-color: white;
`;

export const PaddedCard = ({ children, style = {} }) => {
  return <CardContainer style={style}>{children}</CardContainer>;
};
