import { Button } from "./Button";
import React from "react";
import { ReactComponent as CaretRight } from "../assets/icons/CaretRight.svg";
import { To, useNavigate } from "react-router-dom";

export const BackButton = ({ backTo }: { backTo?: To }) => {
  const navigate = useNavigate();
  return (
    <Button
      text={null}
      icon={CaretRight}
      style={{
        paddingLeft: 16,
        paddingRight: 16,
        paddingTop: 0,
        paddingBottom: 0,
        height: 50,
      }}
      onClick={() => {
        //@ts-ignore
        navigate(backTo || -1);
      }}
      buttonType="secondary"
    />
  );
};
