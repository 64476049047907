import { CSSProperties, FC } from "react";
import styled, { CSSProp } from "styled-components";

interface ITextProps {
  fontWeight?: 100 | 200 | 300 | 400 | 500 | 700 | "normal";
  fontSize?: number;
  children?: any;
  style?: CSSProperties & CSSProp<any>;
  color?: string;
  lineHeight?: number;
  uppercase?: boolean;
  onClick?: () => void;
}

const Text = styled.div<ITextProps>`
  font-family: Archivo;
  font-weight: ${({ fontWeight }) => fontWeight};
  font-size: ${({ fontSize }) => fontSize}px;
  line-height: ${({ lineHeight }) => lineHeight || 2};
  max-width: 100%;
  ${({ color }) => (color ? `color: ${color};` : "")}
  ${({ uppercase }) => uppercase && `text-transform: uppercase;`}
`;

export const CustomText: FC<ITextProps> = ({
  fontSize = 16,
  children,
  fontWeight = "normal",
  lineHeight = 2,
  style,
  color,
  uppercase,
  onClick,
}) => {
  return (
    <Text
      onClick={onClick}
      fontSize={fontSize}
      fontWeight={fontWeight}
      lineHeight={lineHeight}
      style={style}
      color={color}
      uppercase={uppercase}
    >
      {children}
    </Text>
  );
};
