import { isMobile } from "react-device-detect";
import { Route, Routes } from "react-router-dom";
import styled from "styled-components";
import { ArchiveListPage } from "../pages/archive/ArchiveListPage";
import { ArchiveQuestionsPage } from "../pages/archive/ArchiveQuestionsPage";
import { ChangePasswordPage } from "../pages/change-password/ChangePasswordPage";
import { ChangePasswordSuccessPage } from "../pages/change-password/ChangePasswordSuccessPage";
import { CommentPage } from "../pages/comment/CommentPage";
import { ContentIntroductionPage } from "../pages/content-introduction/ContentIntroductionPage";
import { ContentPage } from "../pages/content/ContentPage";
import { ForgotPasswordPage } from "../pages/forgot-password/ForgotPasswordPage";
import { ForgotPasswordSuccessPage } from "../pages/forgot-password/ForgotPasswordSuccessPage";
import { FormPage } from "../pages/form/FormPage";
import { HomePage } from "../pages/home/HomePage";
import { LoginPage } from "../pages/login/LoginPage";
import { FinishedEducationPage } from "../pages/success/FinishedEducationPage";
import { FinishedPollPage } from "../pages/success/FinishedPollPage";
import { ProtectedRoute } from "./ProtectedRoute";
import { TaskPage } from "../pages/task/TaskPage";

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: ${isMobile ? "calc(100vh - 80px)" : "100vh"};
  width: 100vw;
`;

export const AppRouter = (): JSX.Element => {
  return (
    <MainContainer>
      <Routes>
        {/* @ts-ignore */}
        <Route element={<ProtectedRoute />}>
          <Route path={"/"} element={<HomePage />} />
          <Route
            path={"/content/:id/intro"}
            element={<ContentIntroductionPage />}
          />
          <Route path={"/content/:id"} element={<ContentPage />} />
          <Route path={"/content/:id/poll"} element={<FormPage />} />
          <Route path={"/content/:id/quiz"} element={<FormPage />} />
          <Route
            path={"/content/:id/poll/end"}
            element={<FinishedPollPage />}
          />
          <Route
            path={"/content/:id/quiz/end"}
            element={<FinishedEducationPage />}
          />
          <Route path={"/content/:id/comment"} element={<CommentPage />} />
          <Route path={"/archive"} element={<ArchiveListPage />} />
          <Route
            path={"/archive/:id"}
            element={<ContentIntroductionPage isArchive />}
          />
          <Route
            path={"/archive/:id/questions"}
            element={<ArchiveQuestionsPage />}
          />
          <Route
            path={"/archive/:id/content"}
            element={<ContentPage isArchive />}
          />
          <Route path={"/task/:id"} element={<TaskPage />} />
          <Route path={"/change-password"} element={<ChangePasswordPage />} />
          <Route
            path={"/change-password/success"}
            element={<ChangePasswordSuccessPage />}
          />
        </Route>

        <Route path={"/login"} element={<LoginPage />} />
        <Route path={"/forgot-password"} element={<ForgotPasswordPage />} />
        <Route
          path={"/forgot-password/success"}
          element={<ForgotPasswordSuccessPage />}
        />

        <Route path="*" element={<p>There's nothing here: 404!</p>} />
      </Routes>
    </MainContainer>
  );
};
