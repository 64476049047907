import { useMemo } from "react";
import { useQuery } from "react-query";
import { axiosInstance } from "../api/http";
import { useRecoilValue } from "recoil";
import { userIdAtom } from "../state/state";

export const useNewContentWithAttempts = () => {
  const userId = useRecoilValue(userIdAtom);

  const storeKey = useMemo(
    () => ["user", userId, "new-content-attempts"],
    [userId]
  );
  return useQuery(
    storeKey,
    axiosInstance.mobile.contentControllerGetNewContentWithAttempts,
    {
      enabled: !!userId,
      select: ({ data }) => data.payload,
      retry: false,
    }
  );
};
