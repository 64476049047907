import { BrowserView, isMobile, MobileView } from "react-device-detect";
import "react-dropdown/style.css";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Button } from "../../components/Button";
import { CenteredCard } from "../../components/cards/CenteredCard";
import { CustomText } from "../../components/CustomText";
import Colors from "../../utils/Colors";
import { ReactComponent as Correct } from "../../assets/icons/correct.svg";
import { useMemo } from "react";
const HomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${Colors.background};
  flex: 1;
`;

const HomeContent = styled.div`
  max-width: 936px;
  width: 100%;
`;

const HeaderRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
  margin-bottom: 15px;
`;

const StyledMobileView = styled(MobileView)`
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
`;

export const ChangePasswordSuccessPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const innerContent = useMemo(
    () => (
      <>
        <Correct width={42} height={42} style={{ paddingBottom: 10 }} />
        <CustomText
          fontSize={24}
          fontWeight={500}
          color={Colors.darkGray}
          style={{ marginBottom: 40 }}
        >
          {t("passwordChangedSuccessfully")}
        </CustomText>
        <Button
          text="POVRATAK NA NASLOVNICU"
          onClick={() => navigate("/")}
          buttonType="primary"
        />
      </>
    ),
    [navigate, t]
  );

  return (
    <HomeContainer>
      <HomeContent>
        <HeaderRow
          style={{
            marginLeft: isMobile ? 20 : 0,
            marginRight: isMobile ? 20 : 0,
            flexDirection: isMobile ? "column" : "row",
          }}
        >
          {!isMobile && (
            <CustomText fontSize={24} color={Colors.darkGreen}>
              {t("changePassword")}
            </CustomText>
          )}
        </HeaderRow>
        <BrowserView>
          <CenteredCard
            style={{ marginTop: 30, paddingTop: 130, paddingBottom: 130 }}
          >
            {innerContent}
          </CenteredCard>
        </BrowserView>
        <StyledMobileView>{innerContent}</StyledMobileView>
      </HomeContent>
    </HomeContainer>
  );
};
