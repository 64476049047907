import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { useRecoilValue } from "recoil";
import { userIdAtom } from "../state/state";
import { axiosInstance } from "../api/http";

export const useNewContent = () => {
  const userId = useRecoilValue(userIdAtom)

  const storeKey = useMemo(() => ['user', userId, 'new-content'], [userId]);
  return useQuery(storeKey, () => axiosInstance.mobile.contentControllerGetNewContent({ limit: 50, offset: 0 }), {
    enabled: !!userId,
    select: ({ data }) => data.payload,
    retry: false
  });
};
