import { NavLink, useLocation } from "react-router-dom";
import styled from "styled-components";
import { ReactComponent as Home } from "../assets/icons/tab-bar-icons/home.svg";
import { ReactComponent as HomeActive } from "../assets/icons/tab-bar-icons/home-selected.svg";
import { ReactComponent as Archive } from "../assets/icons/tab-bar-icons/folder-open.svg";
import { ReactComponent as ArchiveActive } from "../assets/icons/tab-bar-icons/folder-open-selected.svg";
import { ReactComponent as User } from "../assets/icons/tab-bar-icons/user.svg";
import { ReactComponent as UserActive } from "../assets/icons/tab-bar-icons/user-selected.svg";
import { useState } from "react";
import { ProfileModal } from "./ProfileModal";
import { ClientLogo } from "./ClientLogo";
import { isMobile } from "react-device-detect";
import { useSetRecoilState } from "recoil";
import { archivePageAtom } from "../state/state";

const HeaderComponent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  min-height: 80px;
  position: relative;
`;

const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  max-width: 936px;
  margin: 0 20px;
`;

const LinkContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const IconContainer = styled.div`
  margin-left: ${isMobile ? 30 : 40}px;
`;

export const Header = () => {
  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);
  const { pathname } = useLocation();
  const setArchivePage = useSetRecoilState(archivePageAtom);
  return (
    <HeaderComponent>
      <HeaderContent>
        <ClientLogo />
        <LinkContainer>
          <IconContainer>
            <NavLink to={"/"}>
              {({ isActive }) => (
                <>
                  {(isActive ||
                    (pathname.includes("content") &&
                      !pathname.includes("archive"))) &&
                  !isProfileModalOpen ? (
                    <HomeActive />
                  ) : (
                    <Home />
                  )}
                </>
              )}
            </NavLink>
          </IconContainer>
          <IconContainer>
            <NavLink to={"/archive"} onClick={() => setArchivePage(0)}>
              {({ isActive }) => (
                <>
                  {isActive && !isProfileModalOpen ? (
                    <ArchiveActive />
                  ) : (
                    <Archive />
                  )}
                </>
              )}
            </NavLink>
          </IconContainer>

          <IconContainer>
            {isProfileModalOpen || pathname.includes("change-password") ? (
              <UserActive
                onClick={() => setIsProfileModalOpen(!isProfileModalOpen)}
              />
            ) : (
              <User
                onClick={() => setIsProfileModalOpen(!isProfileModalOpen)}
              />
            )}
            {isProfileModalOpen && (
              <ProfileModal closeModal={() => setIsProfileModalOpen(false)} />
            )}
          </IconContainer>
        </LinkContainer>
      </HeaderContent>
    </HeaderComponent>
  );
};
