import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { useRecoilValue } from "recoil";
import { userIdAtom } from "../state/state";
import { axiosInstance } from "../api/http";

export const useContentDetails = ({ contentId }: { contentId: number }) => {
  const userId = useRecoilValue(userIdAtom)

  const storeKey = useMemo(() => ['user', userId, 'new-content', contentId], [contentId, userId]);

  return useQuery(storeKey, () => axiosInstance.mobile.contentControllerGetContentById(contentId), {
    enabled: !!userId && !!contentId,
    select: ({ data }) => data.payload
  });
};
