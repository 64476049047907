import React, { FC } from "react";
import styled from "styled-components";
import Colors from "../utils/Colors";

const Container = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
`;

const HeaderText = styled.div`
  font-family: Archivo;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 31px;
  text-align: center;
  color: ${Colors.darkGreen};
  margin-bottom: 10px;
`;

const InfoText = styled.div`
  font-family: Archivo;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  text-align: center;
  color: ${Colors.gray};
`;

interface Props {
  headerText: string;
  icon: React.FC<React.SVGProps<SVGSVGElement>>;
  infoText: string;
}

export const EmptyState: FC<Props> = ({ icon: Icon, headerText, infoText }) => {
  return (
    <Container>
      <Icon width={54} height={54} style={{ marginBottom: 25 }} />
      <HeaderText>{headerText}</HeaderText>
      <InfoText>{infoText}</InfoText>
    </Container>
  );
};
