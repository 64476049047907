import moment from "moment";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Colors from "../utils/Colors";
import { BASE_URL } from "../utils/shared";
import { CustomText } from "./CustomText";
import { ReactComponent as EducationIcon } from "../assets/icons/content-type-icons/education.svg";
import { ReactComponent as InformaitonIcon } from "../assets/icons/content-type-icons/information.svg";
import { ReactComponent as InquiryIcon } from "../assets/icons/content-type-icons/inquiry.svg";
import { ReactComponent as PollIcon } from "../assets/icons/content-type-icons/poll.svg";

import {
  ContentDto,
  ContentDtoConfigurationEnum,
  ContentDtoTypeEnum,
  FinishedContentDto,
  FinishedContentDtoTypeEnum,
} from "../new-api/api";
import { useTranslation } from "react-i18next";
import { Button } from "./Button";
import { useContentFunctions } from "../hooks/useContentFunctions";

const ItemRowComponent = styled.div`
  display: flex;
  padding: 24px;
  min-height: 160px;
`;

const ImageContainer = styled.div<{ hasMedia: boolean }>`
  display: flex;
  flex: 1;
  position: relative;
  margin-right: 15px;
  background-color: ${Colors.lighterGray};
  align-items: ${({ hasMedia }) => (hasMedia ? "center" : "flex-end")};
  min-height: 150px;
  justify-content: ${({ hasMedia }) => (hasMedia ? "center" : "flex-start")};
`;

const ItemDataContainer = styled.div<{ hidePicture: boolean }>`
  display: flex;
  flex: ${({ hidePicture }) => (hidePicture ? 1 : 2)};
  flex-direction: column;
  justify-content: space-between;
`;

const AbsolutePlaceholder = styled.div`
  position: absolute;
  left: 20px;
  bottom: 20px;
`;

const PromoChip = styled.div`
  padding: 1px 4px;
  background-color: ${Colors.lightRed};
  border-radius: 4px;
  font-family: Archivo;
  font-size: 13px;
  color: white;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
  text-decoration: none;
`;

interface Props {
  item: ContentDto | FinishedContentDto;
  index?: number;
  shouldGoToAnswers?: boolean;
  hidePicture?: boolean;
  navigateTo?: string;
}

export const ItemRow: FC<Props> = ({
  hidePicture = false,
  navigateTo = "/",
  item,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    markContentViewed: { mutateAsync: markContentViewed },
  } = useContentFunctions({
    contentId: Number(item.id),
  });
  const isTask =
    item.type === ContentDtoTypeEnum.TASK ||
    item.type === FinishedContentDtoTypeEnum.TASK;

  return (
    <ItemRowComponent>
      {!hidePicture && (
        <ImageContainer hasMedia={!!item?.image}>
          {item?.image ? (
            <img
              src={`${BASE_URL}/files/${item?.image}`}
              crossOrigin={"use-credentials"}
              alt="Item"
              width="100%"
              height={150}
              style={{
                objectFit: "cover",
                objectPosition: "center",
              }}
            />
          ) : (
            <AbsolutePlaceholder>
              {item.type === ContentDtoTypeEnum.INFORMATION && (
                <InformaitonIcon />
              )}
              {item.type === ContentDtoTypeEnum.EDUCATION && <EducationIcon />}
              {item.type === ContentDtoTypeEnum.POLL && <PollIcon />}
              {item.type === ContentDtoTypeEnum.INQUIRY && <InquiryIcon />}
            </AbsolutePlaceholder>
          )}
        </ImageContainer>
      )}
      <ItemDataContainer hidePicture={hidePicture}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {item.configuration === ContentDtoConfigurationEnum.PROMOTION ? (
            <PromoChip>Promo</PromoChip>
          ) : (
            <CustomText
              fontSize={14}
              lineHeight={1}
              uppercase={true}
              color={Colors.gray100}
            >
              {t(item.type)}
            </CustomText>
          )}

          <div
            style={{
              display: "flex",
              alignItems: "center",
              columnGap: 4,
            }}
          >
            <CustomText color={Colors.gray} fontSize={14}>
              {isTask ? "Rok izvršenja:" : `Objavljeno:`}
            </CustomText>
            <CustomText color={Colors.darkGray} fontWeight={500} fontSize={14}>
              {/*@ts-ignore*/}
              {moment(isTask ? item?.task?.deadline : item?.publishAt).format(
                "DD.MM.YYYY."
              )}
            </CustomText>
          </div>
        </div>

        <CustomText
          fontSize={20}
          lineHeight={1}
          fontWeight={500}
          color={Colors.darkGray}
        >
          {item.name}
        </CustomText>
        {!isTask && (
          <CustomText
            style={{
              display: "-webkit-box",
              "-webkit-box-orient": "vertical",
              maxHeight: "2.5em",
              overflow: "hidden",
              lineHeight: "1.25em",
              "-webkit-line-clamp": 2,
            }}
            fontSize={14}
            lineHeight={1.4}
            color={Colors.darkGray}
          >
            {item.description}
          </CustomText>
        )}
        <div>
          <Button
            text={t("review")}
            buttonType={"primary"}
            onClick={() => {
              markContentViewed();
              navigate(navigateTo);
            }}
          />
        </div>
      </ItemDataContainer>
    </ItemRowComponent>
  );
};
