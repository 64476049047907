import styled from "styled-components";

const CardContainer = styled.div`
  border: 1px solid #f0efed;
  border-radius: 6px;
  padding: 20px 0;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const CenteredCard = ({ children, style = {} }) => {
  return <CardContainer style={style}>{children}</CardContainer>;
};
