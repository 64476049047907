import { useMutation } from "react-query";
import { axiosInstance } from "../api/http";

export const useUserFunctions = () => {
  const resetPassword = useMutation(axiosInstance.mobile.usersControllerResetPassword);

  const changePassword = useMutation(axiosInstance.mobile.usersControllerUpdatePassword);

  const checkHasPassword = useMutation(axiosInstance.mobile.usersControllerCheckHasPassword);

  return {
    resetPassword,
    changePassword,
    checkHasPassword
  };
};
