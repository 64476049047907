import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";
import { AnswerDto } from "../new-api/api";

const { persistAtom } = recoilPersist();

export const tokenAtom = atom({
  key: "tokenAtom",
  default: null,
  effects_UNSTABLE: [persistAtom],
});

export const userIdAtom = atom({
  key: "userId",
  default: null,
  effects_UNSTABLE: [persistAtom],
});

export const currentPageAtom = atom({
  key: "currentPageAtom",
  default: 0,
  effects_UNSTABLE: [persistAtom],
});

export const currentFormAtom = atom<
  { questionId: number; text?: string; answers?: AnswerDto[] }[]
>({
  key: "currentFormAtom",
  default: [],
  effects_UNSTABLE: [persistAtom],
});

export const filtersAtom = atom<{
  contentTypes: string;
  creators: string;
  categories: string;
  periods: string;
}>({
  key: "filtersAtom",
  default: {
    contentTypes: "",
    categories: "",
    periods: "",
    creators: "",
  },
  effects_UNSTABLE: [persistAtom],
});

export const archivePageAtom = atom({
  key: "archivePageAtom",
  default: 0,
  effects_UNSTABLE: [persistAtom],
});

export const homePageAtom = atom({
  key: "homePageAtom",
  default: 0,
  effects_UNSTABLE: [persistAtom],
});

export const remainingSecondsAtom = atom({
  key: "remainingSecondsAtom",
  default: null,
  effects_UNSTABLE: [persistAtom],
});
