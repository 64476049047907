import { useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactPlayer from "react-player";
import Slider, { Settings } from "react-slick";
import styled from "styled-components";
import { CustomText } from "../../components/CustomText";
import "./ContentPage.css";
import Modal from "react-modal";
import { SliderArrow } from "./components/SliderArrow";
import { isMobile } from "react-device-detect";
import { ISlide } from "../../../types";
import { MediaResponseDtoTypeEnum } from "../../new-api/api";
import { BASE_URL } from "../../utils/shared";

const ImageContainer = styled.div``;

interface IContentSlider {
  slides: ISlide[];
  hasSeenAllSlides: boolean;
  setHasSeenAllSlides: (value: boolean) => void;
}

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

export const ContentSlider = ({
  slides,
  hasSeenAllSlides,
  setHasSeenAllSlides,
}: IContentSlider) => {
  const { t } = useTranslation();

  const reactPlayerRef = useRef<ReactPlayer>(null);

  const [slideNum, setSlideNum] = useState(0);
  const [isSliding, setIsSliding] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [imageName, setImageName] = useState(undefined);

  const settings = useMemo(
    () => ({
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      nextArrow: <SliderArrow type="right" />,
      prevArrow: <SliderArrow type="left" />,
    }),
    []
  ) as Settings;

  return (
    <>
      <Slider
        {...settings}
        beforeChange={(_, newIndex) => {
          setIsSliding(true);
          setSlideNum(newIndex);
          if (!hasSeenAllSlides && newIndex === (slides || []).length - 1) {
            setHasSeenAllSlides(true);
          }
        }}
        afterChange={() => {
          setIsSliding(false);
        }}
      >
        {slides.map((item: ISlide) => {
          if (
            item.type === MediaResponseDtoTypeEnum.VIDEO ||
            item.type === "YOUTUBE"
          ) {
            return (
              <div key={item?.url} className="player-wrapper">
                <ReactPlayer
                  controls
                  ref={reactPlayerRef}
                  config={{
                    file: {
                      attributes: {
                        crossOrigin: "use-credentials",
                      },
                    },
                  }}
                  className="react-player"
                  url={
                    item.type === "YOUTUBE"
                      ? item.url
                      : `${BASE_URL}/files/${item.url}`
                  }
                  width="100%"
                  height="100%"
                />
              </div>
            );
          } else if (item.type === MediaResponseDtoTypeEnum.IMAGE) {
            return (
              <ImageContainer
                key={item?.url}
                onClick={() => {
                  if (!isSliding) {
                    setImageName(item.url);
                    setIsOpen(true);
                  }
                }}
              >
                <img
                  src={`${BASE_URL}/files/${item.url}`}
                  crossOrigin={"use-credentials"}
                  alt="Item"
                  width={"100%"}
                  height={isMobile ? 100 : 375}
                  style={{
                    objectFit: "contain",
                    objectPosition: "center",
                  }}
                />
              </ImageContainer>
            );
          } else if (item?.text?.length > 0) {
            return <CustomText key={item?.text}>{item.text}</CustomText>;
          }
          return <CustomText>{t("somethingWentWrong")}</CustomText>;
        })}
      </Slider>
      {(slides || []).length > 0 && (
        <CustomText>
          {slideNum + 1}/{(slides || []).length}
        </CustomText>
      )}
      <Modal
        isOpen={modalIsOpen}
        style={customStyles}
        onRequestClose={() => {
          if (!isSliding) {
            setIsOpen(false);
            setImageName(undefined);
          }
        }}
        shouldCloseOnEsc={true}
        shouldCloseOnOverlayClick={true}
      >
        {imageName && (
          <img
            src={`${BASE_URL}/files/${imageName}`}
            crossOrigin={"use-credentials"}
            alt="Item"
            width={"100%"}
            height={isMobile ? 200 : 700}
            style={{
              objectFit: "contain",
              objectPosition: "center",
            }}
          />
        )}
      </Modal>
    </>
  );
};
