import React from "react";
import styled from "styled-components";
import { ReactComponent as Correct } from "../assets/icons/correct.svg";
import { ReactComponent as Selected } from "../assets/icons/selected-outline.svg";
import { ReactComponent as Wrong } from "../assets/icons/wrong.svg";
import { CustomText } from "./CustomText";
import {
  AnswerDto,
  ContentWithDetailsDtoTypeEnum,
  UserAnswerDto,
} from "../new-api/api";

const WrongCircle = styled(Wrong)`
  width: 24px;
  height: 24px;
  border-radius: 12px;
  margin-right: 10px;
`;

const CorrectCircle = styled(Correct)`
  width: 24px;
  height: 24px;
  border-radius: 12px;
  margin-right: 10px;
`;

const SelectedCircle = styled(Selected)`
  width: 24px;
  height: 24px;
  border-radius: 12px;
  margin-right: 10px;
`;

const AnswerRowContainer = styled.div`
  margin: 5px 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const AnswerContainer = styled.div`
  padding: 20px 0;
  margin-right: 30px;
`;

const AnswerRowStyled = styled.div`
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
`;

const AnswerText = styled(CustomText)`
  font-family: Archivo;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
`;

const AnswerText2 = styled(AnswerText)`
  width: 24px;
  height: 24px;
  border-radius: 12px;
  margin-right: 10px;
  text-align: center;
`;

const AnswerRow = React.memo(
  ({
    answer,
    formType,
  }: {
    answer: AnswerDto;
    formType: ContentWithDetailsDtoTypeEnum;
  }) => {
    return (
      <AnswerRowContainer>
        {formType !== ContentWithDetailsDtoTypeEnum.EDUCATION ? (
          <SelectedCircle />
        ) : answer.isCorrect ? (
          <CorrectCircle />
        ) : (
          <WrongCircle />
        )}
        <CustomText>{answer.title}</CustomText>
      </AnswerRowContainer>
    );
  }
);

export const AnsweredQuestion = (props: {
  userAnswer: UserAnswerDto;
  formType: ContentWithDetailsDtoTypeEnum;
}) => {
  return (
    <AnswerContainer>
      <AnswerRowStyled>
        <AnswerText2>{props.userAnswer.question.order + 1}.</AnswerText2>
        <AnswerText>{props.userAnswer.question.title}</AnswerText>
      </AnswerRowStyled>
      {props?.userAnswer?.text && (
        <CustomText>{props.userAnswer.text}</CustomText>
      )}
      {(props?.userAnswer?.answers || []).map((ans) => (
        <AnswerRow answer={ans} formType={props.formType} key={ans.id} />
      ))}
    </AnswerContainer>
  );
};
