import { useMemo, useState } from "react";
import { BrowserView, isMobile, MobileView } from "react-device-detect";
import "react-dropdown/style.css";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { ReactComponent as PasswordHidden } from "../../assets/icons/password-hidden.svg";
import { ReactComponent as PasswordShown } from "../../assets/icons/password-shown.svg";
import { Button } from "../../components/Button";
import { CenteredCard } from "../../components/cards/CenteredCard";
import { CustomText } from "../../components/CustomText";
import { Container, Content } from "../../components/styled-components";
import { TextInput } from "../../components/TextInput";
import Colors from "../../utils/Colors";
import { useUserFunctions } from "../../hooks/useUserFunctions";

const ItemsContainer = styled.div`
  overflow: scroll;
  height: 100%;
`;

const HeaderRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
  margin-bottom: 15px;
`;

const StyledMobileView = styled(MobileView)`
  padding-left: 20px;
  padding-right: 20px;
`;

type FormValues = {
  oldPassword?: string;
  newPassword?: string;
  confirmNewPassword?: string;
};

export const ChangePasswordPage = () => {
  const { t } = useTranslation();
  const { changePassword: { mutateAsync: changePassword, isLoading: isChangingPassword } } = useUserFunctions();
  const [oldPasswordShown, setOldPasswordShown] = useState(false);
  const [newPasswordShown, setNewPasswordShown] = useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);
  const [showError, setShowError] = useState(false);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      oldPassword: "",
      newPassword: "",
      confirmNewPassword: "",
    },
    shouldFocusError: true,
  });


  const onSubmit = handleSubmit(async (values) => {
    if (values.newPassword !== values.confirmNewPassword) {
      setShowError(true);
      return;
    }
    if (values.newPassword && values.oldPassword) {
      const resp = await changePassword({
        new: values.newPassword,
        old: values.oldPassword
      });

      if (resp?.status >= 200 && resp?.status < 300) {
        setShowError(false);
        // prompt();
        navigate(-1)
      } else {
        setShowError(true);
      }
    }
  });

  const innerContent = useMemo(
    () => (
      <ItemsContainer>
        <TextInput
          name="oldPassword"
          label="Trenutna lozinka"
          type={oldPasswordShown ? "text" : "password"}
          options={{
            required: { value: true, message: "Obavezno" },
          }}
          register={register}
          error={errors?.["oldPassword"]?.message}
          rightIcon={
            <div onClick={() => setOldPasswordShown(!oldPasswordShown)}>
              {oldPasswordShown ? (
                <PasswordShown fill={Colors.primary} stroke={Colors.primary} />
              ) : (
                <PasswordHidden fill={Colors.primary} stroke={Colors.primary} />
              )}
            </div>
          }
        />

        <TextInput
          name="newPassword"
          label="Nova Lozinka"
          type={newPasswordShown ? "text" : "password"}
          options={{
            required: { value: true, message: "Obavezno" },
          }}
          register={register}
          error={errors?.["newPassword"]?.message}
          rightIcon={
            <div onClick={() => setNewPasswordShown(!newPasswordShown)}>
              {newPasswordShown ? (
                <PasswordShown fill={Colors.primary} stroke={Colors.primary} />
              ) : (
                <PasswordHidden fill={Colors.primary} stroke={Colors.primary} />
              )}
            </div>
          }
        />
        <TextInput
          name="confirmNewPassword"
          label="Potvrdite Novu Lozinku"
          type={confirmPasswordShown ? "text" : "password"}
          options={{
            required: { value: true, message: "Obavezno" },
          }}
          register={register}
          error={errors?.["confirmNewPassword"]?.message}
          rightIcon={
            <div onClick={() => setConfirmPasswordShown(!confirmPasswordShown)}>
              {confirmPasswordShown ? (
                <PasswordShown fill={Colors.primary} stroke={Colors.primary} />
              ) : (
                <PasswordHidden fill={Colors.primary} stroke={Colors.primary} />
              )}
            </div>
          }
        />
        <Button
          text={t("changePassword")}
          buttonType="primary"
          type="submit"
          loading={isChangingPassword}
          style={{ width: isMobile && "100%" }}
        />
        {showError && (
          <CustomText style={{ color: Colors.lightRed }}>
            Nešto je pošlo po krivu
          </CustomText>
        )}
      </ItemsContainer>
    ),
    [
      confirmPasswordShown,
      errors,
      isChangingPassword,
      newPasswordShown,
      oldPasswordShown,
      register,
      showError,
      t,
    ]
  );

  return (
    <Container>
      <Content>
        <HeaderRow
          style={{
            marginLeft: isMobile ? 20 : 0,
            marginRight: isMobile ? 20 : 0,
            flexDirection: isMobile ? "column" : "row",
          }}
        >
          <CustomText fontSize={24} color={Colors.darkGreen}>
            {t("changePassword")}
          </CustomText>
        </HeaderRow>
        <form onSubmit={onSubmit}>
          <BrowserView>
            <CenteredCard
              style={{ marginTop: 30, paddingTop: 80, paddingBottom: 80 }}
            >
              {innerContent}
            </CenteredCard>
          </BrowserView>

          <StyledMobileView>{innerContent}</StyledMobileView>
        </form>
      </Content>
    </Container>
  );
};
