import { Ring } from "@uiball/loaders";
import React, { useMemo } from "react";
import { BrowserView, isMobile, MobileView } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { Button } from "../../components/Button";
import { CustomText } from "../../components/CustomText";
import {
  Container,
  Content as ExContent,
} from "../../components/styled-components";
import Colors from "../../utils/Colors";
import { useContentDetails } from "../../hooks/useContentDetails";
import { BackButton } from "../../components/BackButton";
import { useContentFunctions } from "../../hooks/useContentFunctions";
import moment from "moment/moment";
import { Loader } from "../../components/Loader";

const Content = styled(ExContent)<{ isLoading: boolean }>`
  display: flex;
  justify-content: ${({ isLoading }) => (isLoading ? "center" : "flex-start")};
  flex-direction: column;
  border-radius: 6px;
  background-color: white;
  margin-top: 50px;
  padding-top: 40px;
  padding-bottom: 40px;
`;

const InnerContent = styled.div`
  margin-left: 36px;
  margin-right: 36px;
`;

const UpperContent = styled.div`
  padding-top: ${isMobile ? 20 : 0}px;
  padding-left: ${isMobile ? 20 : 0}px;
  padding-right: ${isMobile ? 20 : 0}px;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
  column-gap: 16px;
  ${isMobile && "align-items: center; flex-direction: column;"}
`;

export const TaskPage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { data: content, isLoading } = useContentDetails({
    contentId: Number(id),
  });
  const contentInteractionWithFinishedAt = (
    content?.contentInteractions || []
  ).find((ci) => !!ci.finishedAt);

  const {
    markContentFinished: {
      mutateAsync: markContentFinished,
      isLoading: isMarkingContentFinished,
    },
  } = useContentFunctions({ contentId: Number(id) });

  const { t } = useTranslation();

  const innerContent = useMemo(
    () => (
      <InnerContent>
        <UpperContent>
          <CustomText
            fontSize={13}
            lineHeight={1.4}
            uppercase
            color={Colors.gray}
            style={{ marginBottom: 10 }}
          >
            {t(content?.type) || ""}
          </CustomText>
          <CustomText
            fontSize={20}
            lineHeight={1}
            fontWeight={500}
            color={Colors.darkGray}
            style={{ marginBottom: 10 }}
          >
            {content?.name || ""}
          </CustomText>

          <CustomText
            fontSize={14}
            lineHeight={1.4}
            color={Colors.darkGray}
            style={{ marginBottom: 12 }}
          >
            {content?.description || ""}
          </CustomText>
          <CustomText
            fontSize={14}
            lineHeight={1.4}
            uppercase
            color={Colors.gray}
          >
            {t("taskBy")}
          </CustomText>
          <CustomText
            fontSize={16}
            lineHeight={1.4}
            fontWeight={500}
            style={{ marginBottom: 20 }}
          >
            {content?.creator?.name}
          </CustomText>
          <CustomText
            fontSize={14}
            lineHeight={1.4}
            uppercase
            color={Colors.gray}
          >
            {t(contentInteractionWithFinishedAt ? "finished" : "deadline")}
          </CustomText>
          <CustomText
            fontSize={16}
            lineHeight={1.4}
            fontWeight={500}
            style={{ marginBottom: 20 }}
          >
            {moment(
              contentInteractionWithFinishedAt
                ? contentInteractionWithFinishedAt.finishedAt
                : content?.task?.deadline
            ).format("DD.MM.YYYY., HH:mm")}
          </CustomText>
        </UpperContent>

        <ButtonContainer>
          <BackButton />
          {!contentInteractionWithFinishedAt && (
            <Button
              loading={isMarkingContentFinished}
              text={t("markAsDone")}
              onClick={async () => {
                await markContentFinished();
                navigate(-1);
              }}
              buttonType="primary"
            />
          )}
        </ButtonContainer>
      </InnerContent>
    ),
    [
      content?.creator?.name,
      content?.description,
      content?.name,
      content?.task?.deadline,
      content?.type,
      contentInteractionWithFinishedAt,
      isMarkingContentFinished,
      markContentFinished,
      navigate,
      t,
    ]
  );

  return (
    <Container
      style={{ backgroundColor: isMobile ? Colors.white : Colors.background }}
    >
      <BrowserView renderWithFragment>
        <Content isLoading={isLoading}>
          {isLoading || !content ? <Loader /> : innerContent}
        </Content>
      </BrowserView>
      <MobileView>
        {isLoading || !content ? (
          <Ring color={Colors.primaryGreen} />
        ) : (
          innerContent
        )}
      </MobileView>
    </Container>
  );
};
