import styled from "styled-components";
import { useUser } from "../hooks/useUser";
import { CustomText } from "./CustomText";
import { BASE_URL } from "../utils/shared";

const ClientLogoContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 50px;
  align-items: center;
  justify-content: center;
  align-self: center;
`;

export const ClientLogo = () => {
  const { data: userData } = useUser();
  return (
    <ClientLogoContainer>
      {userData?.tenant?.image && (
        <img
          alt="client-logo"
          crossOrigin={"use-credentials"}
          src={`${BASE_URL}/files/${userData?.tenant?.image}`}
          style={{
            height: 40,
            width: 40,
            marginRight: 10,
            objectFit: "contain",
          }}
        />
      )}
      <CustomText>{userData?.tenant?.name || ""}</CustomText>
    </ClientLogoContainer>
  );
};
