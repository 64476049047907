import { useQuery } from "react-query";
import { useRecoilValue } from "recoil";
import { archivePageAtom, filtersAtom, userIdAtom } from "../state/state";
import { axiosInstance } from "../api/http";
import { ITEMS_PER_PAGE } from "../utils/shared";

export const useFinishedContent = () => {
  const userId = useRecoilValue(userIdAtom);
  const archivePageNumber = useRecoilValue(archivePageAtom);
  const params = useRecoilValue(filtersAtom);
  const storeKey = [
    "user",
    userId,
    "finished-content",
    params.contentTypes,
    params.periods,
    params.creators,
    params.categories,
    archivePageNumber,
  ];
  return useQuery(
    storeKey,
    () =>
      // @ts-ignore
      axiosInstance.mobile.contentControllerGetFinishedContent({
        limit: ITEMS_PER_PAGE,
        offset: archivePageNumber * ITEMS_PER_PAGE,
        ...params,
      }),
    {
      enabled: !!userId,
      select: ({ data }) => data.payload,
    }
  );
};
